import React, {useContext} from "react";
import PropTypes from "prop-types";
import {SelectedOrganizationContext} from "../context/SelectedOrganizationContextProvider";
import {OrganizationListContext} from "../context/OrganizationListContextProvider";
import {NavDropdown} from "react-bootstrap";
import {AppConfigContext} from "../context/AppConfigContextProvider";

function AuthorizationRequired({children}){

    const {organization, zone} = useContext(SelectedOrganizationContext);
    const {config} = useContext(AppConfigContext);
    const {loggedIn, orgList} = useContext(OrganizationListContext);

    if (organization === undefined)
        return <div>
            <h1>Organization not available.</h1>
            <p>Organization does not exist or user does not have access to this organization.</p>
            {
                loggedIn
                    ? <p>If this is a private organization, please ensure you have access. <a href={config.logoutUrl}>Logout</a> and then log in as an authorized user.
                    </p>
                    : <p>
                        If this is a private organization, please <a href={config.authenticationUrl}>Sign In</a> for access.
                    </p>
            }
        </div>;

    return <>{children}</>;
}

AuthorizationRequired.propTypes = {
    children: PropTypes.element
};

export default AuthorizationRequired;

import React, {useRef, useEffect, useMemo, useState} from "react";
import {useBoxDimensions, useWindowDimensions} from "../utils/windowDimensions";
import PropTypes from "prop-types";
import {DateRangePicker} from "@blueprintjs/datetime";


function startOfDay(date){
    let newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
}

function endOfDay(date){
    let newDate = new Date(date);
    newDate.setHours(23, 59, 59, 0);
    return newDate;
}

function DateRangeSelector(props){
    const {width: windowWidth, height: windowHeight} = useWindowDimensions();
    const {
        timeframe, setCustomTimeframe:setCustomQueryTimeframe, organization
    } = props;
    const [customTimeframe, setCustomTimeframe] = useState([startOfDay(timeframe.startTime), endOfDay(timeframe.endTime)]);

    const dateParams = {};

    if (organization.startDate !== null){
        dateParams.minDate = organization.startDate
    }

    if (organization.endDate !== null){
        dateParams.maxDate = organization.endDate
    }

    return <DateRangePicker
        value={customTimeframe}
        singleMonthOnly={windowWidth < 900}
        allowSingleDayRange={true}
        onChange={timerange => {
            setCustomTimeframe(timerange);
            if (timerange[0] && timerange[1]) {
                setCustomQueryTimeframe(timerange);
            }
        }}
        {...dateParams}
    />
}

DateRangeSelector.propTypes = {
    timeframe: PropTypes.object,
    organization: PropTypes.object,
    setCustomTimeframe: PropTypes.func
}

export default DateRangeSelector;
import React, {useState} from "react";
import {layouts, MapMode} from "../MapboxLayouts";
import PropTypes from "prop-types";


const MapboxContext = React.createContext({});

function MapboxContextProvider({children}){

    const availableLayouts = layouts;

    const [mapboxLayout, setMapboxLayout] = useState(availableLayouts[0]);
    const [mapboxMode, setMapboxMode] = useState(MapMode.Normal);

    const context = {
        mapboxMode, setMapboxMode,
        mapboxLayout, setMapboxLayout,
        availableLayouts
    };

    return <MapboxContext.Provider value={context}>
        {children}
    </MapboxContext.Provider>;
}

MapboxContextProvider.propTypes = {
    children: PropTypes.element
};

export {MapboxContextProvider as default, MapboxContext};

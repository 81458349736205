import {Button} from "react-bootstrap";
import React, {useContext} from "react";
import {SelectedOrganizationContext} from "../context/SelectedOrganizationContextProvider";
import {DeviceListContext} from "../context/DeviceListContextProvider";
import {ExclamationTriangleFill, PlusLg} from "react-bootstrap-icons";
import {LinkContainer} from "react-router-bootstrap";

function ZoneConfigurationButton(props) {

    const {organization, zone, getPageUrl} = useContext(SelectedOrganizationContext);

    const {sensors} = useContext(DeviceListContext);
    const existDecommissioned = sensors.some(sensor => sensor.isDecommissioned);

    if (zone === undefined)
        return <div></div>;

    return <LinkContainer to={getPageUrl("setup")}>
        <Button variant={existDecommissioned ? "warning" : "secondary"}>
        {
            existDecommissioned ?
                <span><ExclamationTriangleFill/> New Sensors Found</span>
                : <span><PlusLg/> Add more sensors</span>
        }
        </Button>
    </LinkContainer>;

}

ZoneConfigurationButton.propType = {

}

export default ZoneConfigurationButton;
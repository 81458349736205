    function timeAgo(time){
        const delta = new Date().getTime() - time;
        return delta;
    }

    // TODO customize time threshold for red to green.

    function lastSeenTimeColor(device) {
        return (
            device?.lastSeen !== undefined
                ? (timeAgo(device?.lastSeen) < 360000 ? "green" : "red")
                : "black"
        );
    }

    export {lastSeenTimeColor};
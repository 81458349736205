import {useQuery} from "@tanstack/react-query";
import {Sensor, getEvents} from "../api";
import {FiletypeCsv} from 'react-bootstrap-icons';
import {Button} from "react-bootstrap";
import Moment from "react-moment";
import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import {NonIdealState, Spinner} from "@blueprintjs/core";
import sensors from "../pages/Sensors";
import moment from "moment";

moment.locale('en', {
    longDateFormat : {
        LT: "h:mm:ss A", // <----------- add :ss
        L: "MM/DD/YYYY",
        l: "M/D/YYYY",
        LL: "MMMM Do YYYY",
        ll: "MMM D YYYY",
        LLL: "MMMM Do YYYY LT",
        lll: "MMM D YYYY LT",
        LLLL: "dddd, MMMM Do YYYY LT",
        llll: "ddd, MMM D YYYY LT"
    }
});

function EventView(props){
    const {selectedSensor, organizationId} = props;

    const [limit, setLimit] = useState(10);

    const eventQuery = useQuery({
        queryKey: [`events`, organizationId, selectedSensor.id, limit],
        queryFn: (arg) => {
            return getEvents(organizationId, limit, selectedSensor.id);
        },
        refetchOnWindowFocus: true
    });

    useEffect(() => setLimit(10), [JSON.stringify({id:selectedSensor.id, type:selectedSensor.deviceType})]);

    const dataAvailable = (eventQuery.status === 'success' && eventQuery.data.events);
    const numberOfRecords = dataAvailable ? eventQuery.data.events.length : null;

    if(dataAvailable && numberOfRecords > 0) {
        return (
            <div>
                {
                    eventQuery.data.events.map(
                        event =>
                            <div key={event.id} className="detection">
                                <div>
                                    <b>{event.label}</b>: <Moment calendar>{event.timestamp}</Moment>
                                </div>
                                <div className="latency">
                                    <div>Latency: {event.latency !== null && event.latency > 0 ?
                                        <span>{event.latency} sec </span> : <span>--</span>}</div>
                                    <div>Battery: {event.battery !== null ? <span>{event.battery} %</span> :
                                        <span>--</span>}</div>
                                    <div>Confidence: {event.confidence !== null ? <span>{event.confidence} %</span> :
                                        <span>--</span>}</div>
                                </div>
                                <div className="latency">
                                    <div>
                                        RSSI Up: {
                                            event.rssiUp !== null ?
                                                <span>{event.rssiUp} dBm </span>
                                                : <span>--</span>
                                        }
                                    </div>
                                    <div>
                                        RSSI Down: {
                                            event.rssiDown !== null ?
                                                <span>{event.rssiDown} dBm </span>
                                                : <span>--</span>
                                        }
                                    </div>
                                </div>
                            </div>
                    )
                }
                {
                    (numberOfRecords === limit) ?
                    <div className="text-center">
                        <Button variant="link" onClick={() => setLimit(lim => lim + 10)}> show more </Button>
                    </div> : null
                }

                <div className="csv-download">
                    <a href={`/organization/${organizationId}/events/?limit=40000&sensorId=${selectedSensor.id}&format=csv&mask=false`}>
                        <Button variant="secondary" size="sm"><FiletypeCsv/> CSV</Button>
                    </a>
                </div>
            </div>
        );
    } else if (dataAvailable) {
        return (
            <NonIdealState
                title={"No Events Detected"}
                description={`No events were detected for sensor ${selectedSensor.deviceId}.`}
            />
        )
    } else {
        return <div><Spinner /></div>;
    }
}

EventView.propTypes = {
    selectedSensor: PropTypes.object,
    organizationId: PropTypes.number
}

export default EventView;
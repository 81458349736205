import {Dropdown} from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";


function DeviceSelector(props){
    const {sensors, gateways, onSelectDevice, selectedDevice} = props;

    let sensorLabel = "Devices";
    let sensorDetail = (<div></div>);
    if(selectedDevice !== null && selectedDevice !== undefined) {
        sensorLabel = `${selectedDevice.deviceType}: ${selectedDevice.name ?? selectedDevice.deviceId}`;
    }
    return <Dropdown>
        <Dropdown.Toggle variant="secondary">{sensorLabel}</Dropdown.Toggle>
        <Dropdown.Menu>
            {
                sensors.map(sensor =>
                    <Dropdown.Item
                        key={sensor.id}
                        onClick={() => onSelectDevice(sensor)}>
                        Sensor - {sensor.name ?? sensor.deviceId}
                    </Dropdown.Item>
                )
            }
            {
                gateways.map(gateway =>
                    <Dropdown.Item
                        key={gateway.id}
                        onClick={() => onSelectDevice(gateway)}>
                        Gateway - {gateway.deviceId}
                    </Dropdown.Item>
                )
            }
            {
                (sensors.length === 0 && gateways.length === 0)
                    ? <Dropdown.Item disabled={true} > &lt;no devices found&gt; </Dropdown.Item>
                    : null
            }
        </Dropdown.Menu>
    </Dropdown>
}

DeviceSelector.propTypes = {
    onSelectDevice: PropTypes.func,
    sensors: PropTypes.array,
    gateways: PropTypes.array,
    selectedDevice: PropTypes.object
}

export default DeviceSelector;
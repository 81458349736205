import axios from "axios";
import moment from "moment";
import CryptoJS from "crypto-js";


function rssiToCategory(val){
    if (val === null || val === undefined)
        return undefined;

    if (val > -65)
        return 4;

    if (val > -70)
        return 3;

    if (val > -75)
        return 2;

    if (val > -80)
        return 1;

    return 0;
}

function batteryToCategory(val){
    if (val === null || val === undefined)
        return undefined;

    if (val > 70)
        return 2;

    if (val > 50)
        return 1;

    return 0;
}


class Status {
    constructor(json) {
        this.json = json;
    }

    get battery(){
        return this.json.battery;
    }

    get systemStatus(){
        return this.json.systemStatus;
    }

    get firmwareId(){
        return this.json.firmwareId;
    }

    get timestamp(){
        // Parse as UTC
        return Date.parse(this.json.timestamp + "Z");
    }

    get gatewayTimestamp(){
        // Parse as UTC
        return Date.parse(this.json.gatewayTimestamp + "Z");
    }


    get rssiUp(){
        return this.json.rssiUp;
    }

    get rssiUpCategory(){
        if (this.rssiUp === 0)
            return undefined;
        return rssiToCategory(this.rssiUp)
    }

    get rssiDown(){
        return this.json.rssiDown;
    }

    get rssiDownCategory(){
        if (this.rssiDown === 0)
            return undefined;
        return rssiToCategory(this.rssiDown)
    }

    get batteryCategory(){
        return batteryToCategory(this.battery)
    }
}

class GatewayStatus {
    constructor(json) {
        this.json = json;
    }

    get rssiDown(){
        return this.json.rssiDown;
    }

    get rssiDownCategory(){
        return rssiToCategory(this.rssiDown)
    }
}

class Device {
    constructor(json) {
        this.json = json;
    }

    get currentLocation(){
        const manualLocationList = this.json.locations.filter(location => location.manual);
        if(manualLocationList.length > 0) {
            return manualLocationList[0];
        }
        const currentLocationList = this.json.locations.filter(location => location.current);
        if(currentLocationList.length > 0){
            return currentLocationList[0];
        } else {
            return null;
        }
    }

    get manualLocation(){
        const manualLocationList = this.json.locations.filter(location => location.manual);
        if(manualLocationList.length > 0) {
            return manualLocationList[0];
        } else {
            return null;
        }
    }

    get id(){
        return this.json.id;
    }

    get deviceId(){
        return this.json.deviceId;
    }

    get nwsGridId(){
        if(this.currentLocation !== null){
            return this.currentLocation.nwsGridId;
        } else {
            return null;
        }
    }
}

class Sensor extends Device {
    constructor(sensorJson, statusJson) {
        super(sensorJson);
        this.status = new Status(statusJson);
    }

    get deviceType(){
        return "Sensor";
    }

    get lastSeen(){
        return Date.parse(this.json.lastSeen + "Z");
    }

    get zones(){
        return this.json.zoneSensors;
    }

    get isDecommissioned(){
        return this.json.commissionState === 'UNCOMMISSIONED';
    }

    get isCommissioned(){
        return this.json.commissionState === 'COMMISSIONED';
    }

    get isDeactivated(){
        return this.json.commissionState === 'DEACTIVATED';
    }

    get reportedCommissionState() {
        return this.json.reportedCommissionState;
    }

    get stateConfirmed() {
        return this.json.stateConfirmed;
    }

    get desiredCommissionState() {
        return this.json.commissionState;
    }

    // stateMatch indicates that the sensor is in the desired state, or unknown state
    get stateMatch(){
        return this.stateConfirmed === true || this.stateConfirmed === null || this.reportedCommissionState === null;
    }

    get stateTransitionMessage(){
        if (this.isDeactivated){
            return "Deactivating";
        }
        if (this.isCommissioned){
            return "Commissioning";
        }
        if (this.isDecommissioned){
            return "Decommissioning";
        }
        return undefined;
    }

    get bucket(){

        if (this.reportedCommissionState !== null)
            return this.reportedCommissionState;
        else if (this.desiredCommissionState !== null)
            return this.desiredCommissionState;

        return 'UNCOMMISSIONED';

    }

    get commissionState(){
        return this.json.commissionState;
    }

    get vehicleDetection(){
        return this.json.vehicleDetection !== false;
    }

    get humanDetection(){
        return this.json.humanDetection !== false;
    }

    get animalDetection(){
        return this.json.animalDetection !== false
    }

    get environmentDetection(){
        return this.json.environmentDetection !== false;
    }

    get name(){
        return this.json.name;
    }
}

class Gateway extends Device {
    constructor(json, statusJson) {
        super(json);
        if(statusJson !== undefined)
            this.status = new GatewayStatus(statusJson);
    }

    get deviceId(){
        return this.json.gatewayId;
    }

    get deviceType(){
        return "Gateway";
    }

    get lastSeen(){
        return Date.parse(this.json.lastSeen + "Z");
    }

    get zones(){
        return this.json.zoneGateways;
    }

    get activeZones(){
        return this.zones.filter(zone => zone.active);
    }

    get rssiCategory(){
        return undefined;
    }
}


class Event {
    constructor(json) {
        this.json = json;
    }
    get id(){
        return this.json.id;
    }
    get confidence() {
        return this.json.confidence;
    }
    get label(){
        switch (this.json.detectionType){
            case 0:
                return "Environment"
            case 1:
                return "Vehicle";
            case 2:
                return "Human";
            case 3:
                return "Animal";
            default:
                return "Other";
        }
    }
    get articleLabel(){
        switch (this.json.detectionType){
            case 0:
                return "environment"
            case 1:
                return "a vehicle";
            case 2:
                return "a human";
            case 3:
                return "an animal";
            default:
                return "other";
        }
    }
    get deviceId(){
        return this.json.deviceId;
    }
    get sensorId(){
        return this.json.sensorId;
    }

    get gatewayId(){
        if (this.json.sensorStatus !== null){
            return this.json.sensorStatus.gatewayId;
        } else {
            return null;
        }
    }

    get timestamp(){
        // parse as UTC
        return Date.parse(this.json.timestamp + "Z");
    }

    get gatewayTimestamp(){
        if (this.json.gatewayTimestamp === null){
            return null;
        }
        return Date.parse(this.json.gatewayTimestamp + "Z");
    }

    get latency(){
        if (this.gatewayTimestamp === null){
            return null;
        }
        const gw = moment(this.gatewayTimestamp);
        const device = moment(this.timestamp);
        const diff = gw.diff(device, 'seconds');
        return diff;
    }

    get battery(){
        if (this.json.sensorStatus !== null){
            return this.json.sensorStatus.battery;
        } else {
            return null;
        }
    }
    get rssiUp(){
        if (this.json.sensorStatus !== null){
            return this.json.sensorStatus.rssiUp;
        } else {
            return null;
        }
    }
    get rssiDown(){
        if (this.json.sensorStatus !== null){
            return this.json.sensorStatus.rssiDown;
        } else {
            return null;
        }
    }
}

class Organization {
    constructor(json) {
        this.json = json.organization;
        this.roles = json.roles;
    }

    get id(){
        return this.json.id;
    }

    get private(){
        return this.json.private;
    }

    get name(){
        if(this.json.name === null)
            return `Organization ${this.id}`;
        return this.json.name;
    }

    get startDate(){
        if (this.json.startDate !== null)
            return new Date(Date.parse(this.json.startDate + "Z"))
        else
            return null;
    }

    get endDate(){
        if (this.json.endDate !== null)
            return new Date(Date.parse(this.json.endDate + "Z"));
        else
            return null;
    }

    get zones(){
        return this.json.zones;
    }

    hasRole(role){
        return this.roles.includes(role);
    }
}

class LogEvent {
    constructor(json) {
        this.json = json;
    }

    get id() {
        return this.json.id;
    }

    get message(){
        return this.json.message;
    }

    get level(){
        return this.json.level;
    }

    get timestamp(){
        return Date.parse(this.json.timestamp + "Z");
    }

    get isMultiLine(){
        return this.message.includes("\n");
    }

    get firstLine(){
        return this.message.split("\n")[0];
    }
}

class WeatherGridMetadata {
    constructor(json) {
        this.json = json;
    }
    get id(){
        return this.json.id;
    }
    get name(){
        return this.json.name;
    }
}


class WeatherTypes {
    constructor(json) {
        this.json = json;
        this.typeMap = new Map(json.types.map( type => [ type.id, type]));
        this.nameMap = new Map(json.types.map( type => [ type.name, type]));
    }

    getById(id){
        return this.typeMap.get(id);
    }
    getByName(name){
        return this.nameMap.get(name);
    }
}

class WeatherGrid {
    constructor(json) {
        this.json = json;
    }

    getTemperatureFahrenheit(weatherTypes, fieldName="temperature"){
        const temperatureType = weatherTypes.getByName(fieldName);
        const temperatureObject = this.json.weather.find(it => it.seriesTypeId === temperatureType.id);
        if (temperatureObject !== undefined){
            const temperatureCelcius = temperatureObject.value;
            const temperatureFarenheit = temperatureCelcius * 1.8 + 32;
            return temperatureFarenheit;
        }
        return null;
    }

    buildTrace(seriesType){
        const name = seriesType.description;

        const unitTransfomer = seriesType.unit === 'degC' ? val => (val * 1.8) + 32 : val => val;

        const points = this.json.weather.flatMap((item, idx) => {
            const nextItem = this.json.weather[idx+1];
            const startTime = new Date(item.startTime+"Z").getTime() / 1000;
            const nextStartTime = nextItem !== undefined ?
                new Date(nextItem.startTime+"Z").getTime() / 1000
                : startTime + 3600 * 24; // use a placeholder of 24 hours for max duration if next time doesn't exist.
            const maxDuration = nextStartTime - startTime;
            const hourDuration = (item.duration > 3600) ? Math.round(item.duration / 3600) : 1;
            const hours = [...Array(Math.min(hourDuration, maxDuration)).keys()];
            return hours.map(
                hour => [startTime * 1000 + hour * 3600000, unitTransfomer(item.value)]
            );
        });
        points.sort();
        return {
            x: points.map(item => item[0]),
            y: points.map(item => item[1]),
            yaxis: 'y2',
            name: name,
            type: 'scatter'
        };
    }
}

async function getSensors(organizationId){
    const response = await axios.get(`/organization/${organizationId}/sensors/`);
    const sensors = response.data.result.map(result => new Sensor(result.sensor, result.status));
    return {
        sensors,
        updated: new Date(),
        raw:  CryptoJS.MD5(JSON.stringify(response.data.result)).toString()
    };
}

async function getApiConfig(){
    const response = await axios.get(`/api/config`);
    return response.data;
}

async function getAppParams() {
    const response = await axios.get(`/api/params`);
    return response.data;
}

async function getGatewayAppParams() {
    const response = await axios.get(`/api/gateway_params`);
    return response.data;
}

async function getWeatherTypes(){
    const response = await axios.get(`/api/weather/types`);
    return new WeatherTypes(response.data);
}

async function getWeatherGrid(gridId, current=false, weatherMetricId=null, startTime=null, endTime=null){

    const params = {
        current: current,
        type: weatherMetricId
    };

    if(startTime){
        params['startTime'] = Math.round(startTime.getTime() / 1000);
    }

    if(endTime){
        params['endTime'] = Math.round(endTime.getTime() / 1000);
    }

    const response = await axios.get(`/api/weather/${gridId}`,
        {
            params
        }
    );
    return new WeatherGrid(response.data);
}

async function getGateways(organizationId){
    const response = await axios.get(`/organization/${organizationId}/gateways/`);

    let statusMap = new Map();
    response.data.statuses.forEach(
        status => {
            statusMap.set(status.gatewayId, status);
        }
    )

    const gateways = response.data.gateways.map(result => new Gateway(result, statusMap.get(result.id)));
    return {
        gateways,
        updated: new Date(),
        raw:  CryptoJS.MD5(JSON.stringify(response.data)).toString()
    };
}

async function archiveGateway(organizationId, gatewayId, permanent){
    const response = await axios.post(`/organization/${organizationId}/gateways/${gatewayId}/archive`, {permanent:permanent});
    return response;
}

async function archiveSensor(organizationId, sensorId, permanent){
    const response = await axios.post(`/organization/${organizationId}/sensors/${sensorId}/archive`, {permanent:permanent});
    return response;
}

async function getEvents(
    organizationId, limit=10, sensorId=null, startTime=null, endTime=null, detectionType=null,
    zoneId=null
) {
    let url = `/organization/${organizationId}/events/`;

    const params = { limit };

    if (sensorId !== null){
        params['sensorId'] = sensorId;
    }

    if (startTime !== null){
        params['startTime'] = Math.round(startTime.getTime() / 1000);
    }

    if (endTime !== null){
        params['endTime'] = Math.round(endTime.getTime() / 1000);
    }

    if (detectionType !== null){
        params['detectionType'] = detectionType.join(",")
    }

    if (zoneId !== null && zoneId !== undefined){
        params['zoneId'] = zoneId
    }

    const response = await axios.get(url, {params: params});
    return {
        events: response.data.events.map(event => new Event(event)),
        updated: new Date()
    };
}

async function getOrganizations(){
    const url = "/organization/role/";
    const response = await axios.get(url);
    return {
        organizations: response.data.organizations.map(org => new Organization(org)),
        loggedIn: response.data.loggedIn
    };
}

async function getSensorParamDetails(sensorId, organizationId){
    const response = await axios.get(`/organization/${organizationId}/sensors/${sensorId}/details`);
    return response.data;
}

async function getGatewayParamDetails(gatewayId, organizationId){
    const response = await axios.get(`/organization/${organizationId}/gateways/${gatewayId}/details`);
    return response.data;
}

async function getWeatherGrids(){
    const url = "/api/weather/grids";
    const response = await axios.get(url);
    const gridMetadata = response.data.grids.map(grid => new WeatherGridMetadata(grid));
    return {
        grids:gridMetadata,
        gridMap: new Map(gridMetadata.map(grid => [grid.id, grid]))
    };
}

async function getLogEvents(
    organizationId,
    limit=100,
    sensorId=null,
    gatewayId=null,
    startTime=null,
    endTime=null
) {
    let url = `/organization/${organizationId}/logs/`;

    const params = { limit };

    if (sensorId !== null){
        params['sensorId'] = sensorId;
    }

    if (gatewayId !== null){
        params['gatewayId'] = gatewayId;
    }

    if (startTime !== null){
        params['startTime'] = Math.round(startTime.getTime() / 1000);
    }

    if (endTime !== null){
        params['endTime'] = Math.round(endTime.getTime() / 1000);
    }

    const response = await axios.get(url, {params: params});
    return {
        events: response.data.events.map(event => new LogEvent(event)),
        updated: new Date()
    };
}

export {
    getSensors, getEvents, getOrganizations, getGateways, getLogEvents, getApiConfig, getWeatherTypes, getWeatherGrid, getWeatherGrids,
    Sensor, Event, Status, Organization, Gateway, LogEvent, WeatherTypes, WeatherGrid, WeatherGridMetadata,
    archiveGateway, archiveSensor, getAppParams, getSensorParamDetails,
    getGatewayAppParams, getGatewayParamDetails
};
import {Button} from "@blueprintjs/core";
import PropTypes from "prop-types";
import React from "react";

export function DeactivateButton({sensor, sensorMutation, disabled}){
    return <Button
        variant="secondary"
        size={"sm"}
        disabled={disabled}
        onClick={() => sensorMutation.mutate({deviceId: sensor.id, action: "update", commission: "DEACTIVATED"})}
    >
        Deactivate
    </Button>;
}

DeactivateButton.propTypes = {
    sensor: PropTypes.object,
    sensorMutation: PropTypes.object,
    disabled: PropTypes.bool
}


export function CommissionButton({sensor, sensorMutation, disabled}){
    return <Button
        variant="secondary"
        size={"sm"}
        disabled={disabled}
        onClick={() => sensorMutation.mutate({deviceId: sensor.id, action: "update", commission: "COMMISSIONED"})}
    >
        Commission
    </Button>;
}

CommissionButton.propTypes = {
    sensor: PropTypes.object,
    sensorMutation: PropTypes.object,
    disabled: PropTypes.bool
}

export function ReactivateButton({sensor, sensorMutation, disabled}){
    return <Button
        variant="secondary"
        size={"sm"}
        disabled={disabled}
        onClick={() => sensorMutation.mutate({deviceId: sensor.id, action: "update", commission: "UNCOMMISSIONED"})}
    >
        Reactivate
    </Button>;
}

ReactivateButton.propTypes = {
    sensor: PropTypes.object,
    sensorMutation: PropTypes.object,
    disabled: PropTypes.bool
}

export function RemoveFromZoneButton({sensor, zone, sensorMutation, disabled}){
    return <Button
        intent="danger"
        disabled={disabled}
        onClick={() => sensorMutation.mutate({deviceId: sensor.id, action: "remove", zoneId: zone.id})}
    >
        Remove from zone
    </Button>;
}

RemoveFromZoneButton.propTypes = {
    sensor: PropTypes.object,
    zone: PropTypes.object,
    sensorMutation: PropTypes.object,
    disabled: PropTypes.bool
}


export function BuildButtons({sensor, zone, sensorMutation, disabled:buttonsDisabled}){
    if (sensor.isCommissioned){
        return <DeactivateButton sensor={sensor} sensorMutation={sensorMutation} disabled={buttonsDisabled} />;

    }
    if (!sensor.isCommissioned && !sensor.isDeactivated){
        return <>
            <CommissionButton sensor={sensor} sensorMutation={sensorMutation} disabled={buttonsDisabled} />
            <DeactivateButton sensor={sensor} sensorMutation={sensorMutation} disabled={buttonsDisabled}/>
        </>;
    }
    if (sensor.isDeactivated){
        return <>
            <ReactivateButton sensor={sensor} sensorMutation={sensorMutation} disabled={buttonsDisabled} />
            <RemoveFromZoneButton sensor={sensor} zone={zone} sensorMutation={sensorMutation} disabled={buttonsDisabled} />
        </>;
    }
}


BuildButtons.propTypes = {
    sensor: PropTypes.object,
    zone: PropTypes.object,
    sensorMutation: PropTypes.object,
    disabled: PropTypes.bool
}
import FullscreenSpinner from "../components/FullscreenSpinner";
import React, {useContext, useMemo, useState} from "react";
import OrganizationListContextProvider, {OrganizationListContext} from "./OrganizationListContextProvider";
import PropTypes from "prop-types";
import {last30Days, MapMode} from "../MapboxLayouts";
import {useMutation, useQuery} from "@tanstack/react-query";
import {getApiConfig, getGateways, getSensors, getWeatherGrids, Sensor} from "../api";
import {resolvePath, useLoaderData, useLocation, useMatch, useNavigate, useResolvedPath} from "react-router-dom";
import {SelectedOrganizationContext} from "./SelectedOrganizationContextProvider";
import axios from "axios";


const DeviceListContext = React.createContext({});

function DeviceListContextProvider({children}) {

    const {organization, zone} = useContext(SelectedOrganizationContext);

    const [updatedSensorObj, setUpdatedSensorObj] = useState({});
    const [updatedGatewayObj, setUpdatedGatewayObj] = useState({});

    // Queries
    const sensorQuery = useQuery({
        queryKey:[`sensors`, organization.id],
        queryFn: (arg) => {
            return getSensors(organization.id);
        },
        refetchOnWindowFocus: false,
        refetchInterval: 5000

    });

    const gatewayQuery = useQuery({
        queryKey: [`gateways`, organization.id],
        queryFn: (arg) => {
            return getGateways(organization.id);
        },
        refetchOnWindowFocus: false,
        refetchInterval: 5000
    });

    const sensorMutation = useMutation({
        mutationFn: form => {
            if (form.action === 'update') {
                const location = form.deleteLocation ? [] : form.latitude && form.longitude ? [form.latitude, form.longitude] : null;
                return axios.patch(
                    `/organization/${organization.id}/sensors/${form.deviceId}`,
                    {
                        vehicle_detection: form.vehicleDetection,
                        animal_detection: form.animalDetection,
                        human_detection: form.humanDetection,
                        environment_detection: form.environmentDetection,
                        commission: form.commission,
                        name: form.name,
                        location: location,
                        app_params: form.appParams,
                        restart: form.restart
                    }
                );
            } else if (form.action === 'remove'){
                return axios.delete(
                    `/organization/${organization.id}/sensors/${form.deviceId}/zone/${form.zoneId}`
                );
            }
        },
        onSuccess: (data, variables, context) => {
            setUpdatedSensorObj({
                sensor: data.data.sensor,
                updated: new Date()
            });
            sensorQuery.refetch();
        }
    });

    const gatewayMutation = useMutation({
        mutationFn: form => {
            if (form.action === 'update') {
                const location = form.deleteLocation ? [] : form.latitude && form.longitude ? [form.latitude, form.longitude] : null;
                return axios.patch(
                    `/organization/${organization.id}/gateways/${form.deviceId}`,
                    {
                        location: location,
                        app_params: form.appParams
                    }
                );
            }
        },
        onSuccess: (data, variables, context) => {
            setUpdatedGatewayObj({
                gateway: data.data.gateway,
                updated: new Date()
            });
            gatewayQuery.refetch();
        }
    });

    const gatewayDataAvailable = gatewayQuery.isSuccess && gatewayQuery.data !== undefined;
    const sensorDataAvailable = sensorQuery.isSuccess && sensorQuery.data !== undefined;

    const gatewayMemoTrigger = gatewayDataAvailable ? gatewayQuery.data.raw : null;

    const gateways = useMemo(
        () => {
            if (gatewayDataAvailable) {
                if (zone !== undefined)
                    return gatewayQuery.data.gateways.filter(gw => gw.zones.some(z => z.zoneId === zone.id && z.active));
                else
                    return gatewayQuery.data.gateways;
            } else {
                return [];
            }
        },
        [gatewayMemoTrigger, zone?.id]
    );

    const sensorMemoTrigger = sensorDataAvailable ? sensorQuery.data.raw : null;
    const sensorDateUpdated = sensorDataAvailable ? sensorQuery.data.updated : null;

    const sensors = useMemo(
        () => {
            if (sensorDataAvailable) {
                return sensorQuery.data.sensors;
            } else {
                return [];
            }
        },
        [sensorMemoTrigger]
    );


    const updatedSensors = useMemo(() => {
        const updatedIsNewer = sensorDateUpdated < updatedSensorObj?.updated;
        // console.log({updatedIsNewer, updatedSensorObj, sensorDateUpdated});

        return sensors.map(sensor => {
            const useUpdated = sensor.id === updatedSensorObj?.sensor?.id && updatedIsNewer;
            return useUpdated ? new Sensor(updatedSensorObj.sensor, sensor.status.json): sensor
        });

    }, [sensors, updatedSensorObj]);

    const zoneSensors = useMemo(() => {
        if (zone !== undefined)
            return updatedSensors.filter(sensor => sensor.zones.some(z => z.zoneId === zone.id && z.active));
        else
            return updatedSensors;
        },
        [zone?.id, updatedSensors]
    );

    const context = {
        sensors:zoneSensors,
        gateways,
        sensorQuery, gatewayQuery,
        sensorMutation, gatewayMutation
    };

    if (!sensorDataAvailable || ! gatewayDataAvailable )
        return <FullscreenSpinner />;

    return <DeviceListContext.Provider value={context}>
        { children }
    </DeviceListContext.Provider>;
}

DeviceListContextProvider.propTypes = {
    children: PropTypes.element
};

export {DeviceListContextProvider as default, DeviceListContext};




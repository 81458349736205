import React from 'react';
import PropTypes from "prop-types";
import {useQuery} from "@tanstack/react-query";
import {getOrganizations} from "../api";
import FullscreenSpinner from "../components/FullscreenSpinner";

const OrganizationListContext = React.createContext({});

function OrganizationListContextProvider({children}) {

    const organizationQuery = useQuery({
        queryKey: [`organizations`],
        queryFn: (arg) => {
            return getOrganizations();
        },
        refetchOnWindowFocus: true,
        staleTime: Infinity,
        refetchInterval: 15000
    });

    const organizationDataAvailable = ( organizationQuery.isSuccess && organizationQuery.data );

    if (!organizationDataAvailable)
        return  <FullscreenSpinner />;

    const loggedIn = organizationQuery.data.loggedIn;
    const orgList = organizationQuery.data.organizations;

    const context = {loggedIn, orgList, organizationQuery};

    return <OrganizationListContext.Provider value={context}>
        { children }
    </OrganizationListContext.Provider>;
}

OrganizationListContextProvider.propTypes = {
    children: PropTypes.element
};

export {OrganizationListContextProvider as default, OrganizationListContext};
import React, {useRef, useEffect, useMemo, useState, useContext} from "react";
import PropTypes from "prop-types";
import {Gateway, Sensor} from "../api";
import {MapboxLayout, MapMode} from "../MapboxLayouts";
import {Row, Col, Button} from "react-bootstrap";
import { Pause,  Play, SkipStart } from 'react-bootstrap-icons';
import { clusterEvents } from "../utils/clusterPoints";
import { Slider, Switch } from "@blueprintjs/core";
import _ from 'lodash';
import mapboxgl from '!mapbox-gl';
import Moment from "react-moment";
import ClusterRowView from "./ClusterRowView";
import {AppConfigContext} from "../context/AppConfigContextProvider";


function MapboxPlot(props){

    const {
        sensors,
        gateways,
        selectedSensor,
        clickSensor,
        alerts,
        events,
        mapboxLayout,
        mapMode,
        timeframe:queryTimeframe,
    } = props;

    // mapbox object
    const map = useRef(null);
    const hoverSensors = useRef([]);
    const hoverGateways = useRef([]);
    const sensorRef = useRef([]);
    const gatewayRef = useRef([]);

    const {config} = useContext(AppConfigContext);

    // map container HTML element
    const mapContainer = useRef(null);

    const [position, setPosition] = useState(0);

    const compactSensors = sensors.map(sensor => {
        return {
            id: sensor.id,
            currentLocation: sensor.currentLocation ? {
                latitude: sensor.currentLocation.latitude,
                longitude: sensor.currentLocation.longitude,
            } : null,
            deviceId: sensor.deviceId
        };
    });

    const compactGateways = gateways.map(device => {
        return {
            id: device.id,
            currentLocation: device.currentLocation ? {
                latitude: device.currentLocation.latitude,
                longitude: device.currentLocation.longitude,
            } : null,
            gatewayId: device.deviceId,
            deviceId: device.deviceId
        };
    });

    const memoizedSensors = useMemo(() => compactSensors, [JSON.stringify(compactSensors)]);
    const memoizedGateways = useMemo(() => compactGateways, [JSON.stringify(compactGateways)]);

    const [timeframe, setTimeframe] = useState(initialTimeframe);

    const [animationSpeed, setAnimationSpeed] = useState(10);

    const [sensorInfo, setSensorInfo] = useState(null);

    const [heatmapClusters, setHeatmapClusters] = useState([]);

    const [mapVersion, setMapVersion] = useState(1);

    const memoizedClusters = useMemo(() => clusterEvents(events), [JSON.stringify(events)]);

    const animationDuration = (timeframe.endTime.getTime() - timeframe.startTime.getTime());
    const animationFrames = 1000000;

    const timestep = new Date(
        timeframe.startTime.getTime() + animationDuration * (position % animationFrames / animationFrames)
    );

    function setTimestep(timestamp){
        const newPosition = (timestamp - timeframe.startTime) /(timeframe.endTime - timeframe.startTime) * animationFrames;
        setPosition(newPosition);
    }

    // Initialize Map
    useEffect(() => {
        if (map.current) return; // initialize map only once

        // Start with US View
        const zoom = 3.4;
        const centerLon = -100;
        const centerLat = 42;

        mapboxgl.accessToken = config.mapboxKey;

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapboxLayout.mapboxStyle,
            center: [centerLon, centerLat],
            zoom: zoom
        });

        map.current.on('load', () => {

            map.current.addSource('sensor-point-source', {
                'type': 'geojson',
                'data': buildGeoJson([], [], null)
            });


            map.current.addSource('gateway-point-source', {
                'type': 'geojson',
                'data': buildGeoJson([], [], null)
            });


            map.current.addSource('sensor-event-source', {
                'type': 'geojson',
                'data': buildGeoJson([], [], null)
            });

            map.current.addSource('sensor-event-animation-source', {
                'type': 'geojson',
                'data': buildGeoJson([], [], null)
            });

            map.current.addLayer({
                id: 'selected-sensor',
                type: 'circle',
                source: 'sensor-point-source',
                paint: {
                    'circle-color': 'yellow',
                    'circle-opacity': [ 'match',
                        ['get', 'selected'],
                        'selected', 0.4,
                        'deselected', 0,
                        0
                    ],
                    'circle-radius': 30
                }
            });
            map.current.addLayer({
                id: 'selected-gateway',
                type: 'circle',
                source: 'gateway-point-source',
                paint: {
                    'circle-color': 'yellow',
                    'circle-opacity': [ 'match',
                        ['get', 'selected'],
                        'selected', 0.4,
                        'deselected', 0,
                        0
                    ],
                    'circle-radius': 30
                }
            });
            setMapVersion(mapVersion => mapVersion + 1);
        });

        map.current.on('click', 'selected-sensor', (e) => {
            const name = e.features[0].properties.name;
            clickSensor(sensorRef.current.filter(sensor => sensor.id === e.features[0].properties.sensorId)[0]);
        });
        map.current.on('click', 'sensor-points', (e) => {
            const name = e.features[0].properties.name;
            clickSensor(sensorRef.current.filter(sensor => sensor.id === e.features[0].properties.sensorId)[0]);
        });

        map.current.on('click', 'selected-gateway', (e) => {
            const name = e.features[0].properties.name;
            clickSensor(gatewayRef.current.filter(gateway => gateway.id === e.features[0].properties.sensorId)[0]);
        });
        map.current.on('click', 'gateway-points', (e) => {
            const name = e.features[0].properties.name;
            clickSensor(gatewayRef.current.filter(gateway => gateway.id === e.features[0].properties.sensorId)[0]);
        });

        // Remove hover
        function removeHover(){
            hoverSensors.current.forEach(mapboxSensorId => {
                map.current.setFeatureState(
                    {
                        source: 'sensor-point-source',
                        id: mapboxSensorId
                    },
                    {
                        hover: false
                    }
                );
            })
            hoverSensors.current = [];
            hoverGateways.current.forEach(mapboxGatewayId => {
                map.current.setFeatureState(
                    {
                        source: 'gateway-point-source',
                        id: mapboxGatewayId
                    },
                    {
                        hover: false
                    }
                );
            })
            hoverGateways.current = [];
        }

        // Produce hover
        map.current.on('mousemove', 'sensor-points', (event) => {
            map.current.getCanvas().style.cursor = 'pointer';
            // Set constants equal to the current feature's magnitude, location, and time
            const sensorName = event.features[0].properties.name;
            const alerts = event.features[0].properties.alertsPresent ? "Alerts: " + event.features[0].properties.alerts : '';

            // Check whether features exist
            if (event.features.length === 0) {
                setSensorInfo(null);
            } else {
                const sensorMapboxId = event.features[0].id;
                removeHover();
                map.current.setFeatureState(
                    {
                        source: 'sensor-point-source',
                        id: sensorMapboxId
                    },
                    {
                        hover: true
                    }
                );
                hoverSensors.current.push(sensorMapboxId);
                const message =
                    <div>
                        <div><b>Sensor {sensorName}</b></div>
                        <div>{alerts}</div>
                    </div>

                setSensorInfo(message);
            }
        });

        map.current.on('mousemove', 'gateway-points', (event) => {
            map.current.getCanvas().style.cursor = 'pointer';
            // Set constants equal to the current feature's magnitude, location, and time
            const sensorName = event.features[0].properties.name;

            // Check whether features exist
            if (event.features.length === 0) {
                setSensorInfo(null);
            } else {
                const gatewayMapboxId = event.features[0].id;
                removeHover();
                map.current.setFeatureState(
                    {
                        source: 'gateway-point-source',
                        id: gatewayMapboxId
                    },
                    {
                        hover: true
                    }
                );
                hoverGateways.current.push(gatewayMapboxId);
                const message =
                    <div>
                        <div><b>Gateway {sensorName}</b></div>
                    </div>

                setSensorInfo(message);
            }
        });

        map.current.on('mouseleave', 'sensor-points', (event) => {
            setSensorInfo(null);
            removeHover()
        });
        map.current.on('mouseleave', 'gateway-points', (event) => {
            setSensorInfo(null);
            removeHover()
        });

        return () => {
            map.current.remove();
            map.current = null;
        };
    }, [mapboxLayout]);

    function onMapLoad(callback){
        if(
            map.current
            && map.current.getSource
            && map.current.getSource('sensor-point-source')
        ) {
            callback()
        } else {
            map.current.on('load', callback);
        }
    }


    // Update sensorRef with current set of sensors. (functions do not have to reference sensor closure).
    useEffect(() => {
        sensorRef.current = sensors;
    }, [sensors]);

    useEffect( () => {
        gatewayRef.current = gateways;
    }, [gateways]);

    // Define layers based on mapMode
    useEffect(() => {
        onMapLoad(() => {
            if(mapMode == MapMode.Normal){
                map.current.addLayer({
                    id: 'sensor-points',
                    type: 'circle',
                    source: 'sensor-point-source',
                    paint: {
                        'circle-radius': [ 'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            16,
                            8
                        ],
                        "circle-color": [ 'case',
                            ['get', 'alertsPresent'],
                            'red', mapboxLayout.markerColor
                        ]
                    }
                });
                map.current.addLayer({
                    id: 'gateway-points',
                    type: 'circle',
                    source: 'gateway-point-source',
                    paint: {
                        'circle-radius': [ 'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            16,
                            8
                        ],
                        "circle-color": mapboxLayout.gatewayMarkerColor
                    }
                });
            } else {
                map.current.addLayer({
                    id: 'sensor-points',
                    type: 'circle',
                    source: 'sensor-point-source',
                    paint: {
                        'circle-radius': [ 'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            16,
                            8
                        ],
                        "circle-color": mapboxLayout.markerColor
                    }
                });
            }


            if (mapMode === MapMode.Heatmap) {
                map.current.addLayer({
                    id: 'heatmap-test',
                    source: 'sensor-event-source',
                    type: 'heatmap',
                    paint: {
                        'heatmap-radius': 50,
                        'heatmap-opacity': 0.4
                    },

                    // filter: ['get', 'alertsPresent']
                });
            }

            // Style animation
            if (mapMode === MapMode.Animation) {
                map.current.addLayer({
                    id: 'heatmap-test',
                    source: 'sensor-event-animation-source',
                    type: 'heatmap',
                    paint: {
                        'heatmap-radius': 50,
                        'heatmap-opacity': 0.4,
                        'heatmap-weight': ['get', 'weight']
                    },

                    // filter: ['get', 'alertsPresent']
                });
            }
        })
        return () => {
            if(map.current !== null){
                map.current.removeLayer("sensor-points");
                if (map.current.getLayer('heatmap-test')) map.current.removeLayer('heatmap-test');
                if (map.current.getLayer('gateway-points')) map.current.removeLayer('gateway-points');
            }
        }
    }, [mapMode, mapVersion]);

    useEffect(() => {

        const filteredSensors = memoizedSensors.filter(sensor => sensor.currentLocation !== null &&
            !(sensor.currentLocation.latitude === 0 && sensor.currentLocation.longitude === 0)
        );

        if(filteredSensors.length > 0){
            // calculate bounds
            const minX = _.min(filteredSensors.map((sensor) => sensor.currentLocation.longitude));
            const maxX = _.max(filteredSensors.map((sensor) => sensor.currentLocation.longitude));
            const minY = _.min(filteredSensors.map((sensor) => sensor.currentLocation.latitude));
            const maxY = _.max(filteredSensors.map((sensor) => sensor.currentLocation.latitude));

            const xRange = (maxX - minX);
            const yRange = (maxY - minY);

            const xPadding = Math.max(0.2 * xRange, 0.001);
            const yPadding = Math.max(0.2 * yRange, 0.001);

            const minXbound = minX - xPadding;
            const maxXbound = maxX + xPadding;
            const minYbound = minY - yPadding;
            const maxYbound = maxY + yPadding;

            onMapLoad(() => {
                map.current.fitBounds([
                    [minXbound, minYbound],
                    [maxXbound, maxYbound]
                ]);
            });
        }
    }, [mapboxLayout, mapMode, memoizedSensors]);

    function toggleClusterHeatmap(cluster){
        setHeatmapClusters(prev => {
            if (heatmapClusters.includes(cluster)){
                setHeatmapClusters(_.filter(prev, item => item != cluster));
            } else {
                setHeatmapClusters([...prev, cluster ]);
            }
        });
    }

    function redraw(){
        const filteredSensors = memoizedSensors.filter(sensor => sensor.currentLocation !== null);
        const selected = selectedSensor instanceof Sensor ? selectedSensor : null;
        const geojson = buildGeoJson(filteredSensors, alerts, selected);

        onMapLoad(() => {
            map.current.getSource('sensor-point-source').setData(geojson);
        });
    }

    function redrawGateways(){
        const filteredGateways = memoizedGateways.filter(gateway => gateway.currentLocation !== null);
        const selected = selectedSensor instanceof Gateway ? selectedSensor : null;
        const geojson = buildGeoJson(filteredGateways,[], selected);

        onMapLoad(() => {
            map.current.getSource('gateway-point-source').setData(geojson);
        });
    }

    function updateEventsTimeframe(){
        const eventTimes = events.map(event => event.timestamp);
        setTimeframe({
            startTime: new Date(_.min(eventTimes)),
            endTime: new Date(_.max(eventTimes)),
            isValid: events.length > 0
        });
    }

    function redrawEventsHeatmap(){
        const geojson = buildGeoEventsHeatmap(events, heatmapClusters);

        onMapLoad(() => {
                map.current.getSource('sensor-event-source').setData(geojson);
        });
    }

    function redrawEventsAnimation(position, timestep){
        const geojson = buildGeoEventsAnimation(events, timestep, position);
        onMapLoad(() => {
            map.current.getSource('sensor-event-animation-source').setData(geojson);
        });
    }


    // Rebuild dataset
    useEffect(redraw, [memoizedSensors, selectedSensor, mapVersion]);
    useEffect(redrawGateways, [memoizedGateways, selectedSensor, mapVersion]);

    // Rebuild events
    useEffect(redrawEventsHeatmap, [events, memoizedSensors, heatmapClusters, mapVersion]);
    useEffect(updateEventsTimeframe, [events]);

    // Reset heatmap clusters when memoizedClusters change.
    useEffect(() => {
        setHeatmapClusters([]);
    }, [memoizedClusters]);

    // Rebuild Animation
    useEffect(
        () => redrawEventsAnimation(position, timestep),
        [memoizedSensors, selectedSensor, position]
    );

    // If animation is enabled, update position based on animationSpeed
    useEffect(() => {
        if(mapMode === MapMode.Animation && animationSpeed !== 0){
            const interval = setInterval(() => {
                setPosition(pos => pos + animationSpeed);
            }, 10);

            return () => {
                clearInterval(interval);
            };
        }
    }, [mapMode, animationSpeed]);

    return <div>
        {sensorInfo ?
            <div className='sensor-info'>
                {sensorInfo}
            </div>: null
        }
        <div ref={mapContainer} className="map-container" />
        {(mapMode == MapMode.Animation && timeframe.isValid) ? <div className="time-controls">
            <Row className="timestamp-row">
                <Col md={6} xs={12}><b>Events:</b> <Moment format="YYYY/MM/DD H:mm">{timeframe.startTime}</Moment> - <Moment format="YYYY/MM/DD H:mm">{timeframe.endTime}</Moment></Col>
                <Col md={6} xs={12}><b>Playback Time:</b> <Moment format="YYYY/MM/DD H:mm">{timestep}</Moment></Col>
            </Row>
            <Row className="control-button-row" >
                <Col>
                    <div className="d-flex">
                        <Button onClick={() => setPosition(0)}><SkipStart /></Button>
                        <Button onClick={() => setAnimationSpeed((speed) => -10)}><Play className="icon-flipped"/></Button>
                        <Button onClick={() => setAnimationSpeed((speed) => 0)}><Pause /></Button>
                        <Button onClick={() => setAnimationSpeed((speed) => 10)}><Play /></Button>
                    </div>
                </Col>
                <Col className="control-button-slider">
                    <div className="d-flex">
                        <Slider
                            min={-100}
                            max={100}
                            stepSize={1}
                            labelStepSize={20}
                            onChange={(value) => setAnimationSpeed(value)}
                            value={animationSpeed}
                            vertical={false}
                        />
                    </div>
                </Col>
            </Row>
            <ClusterRowView clusters={memoizedClusters}
                            onSelectCluster={cluster => setTimestep(cluster.minTimestamp)}
                            clusterVariant={cluster => timestep >= cluster.minTimestamp && timestep < cluster.maxTimestamp ? "primary" : "secondary"}
                            clusterColClass={cluster => cluster.isSensorPresent(selectedSensor) ? "sensor-present" : "sensor-not-present"} />
        </div> : null}
        {(mapMode == MapMode.Heatmap && timeframe.isValid) ?
            <ClusterRowView clusters={memoizedClusters}
                            onSelectCluster={cluster => toggleClusterHeatmap(cluster)}
                            clusterVariant={cluster => heatmapClusters.includes(cluster) ? "primary" : "secondary"}
                            clusterColClass={cluster => cluster.isSensorPresent(selectedSensor) ? "sensor-present" : "sensor-not-present"} />
            : null}
        {((mapMode == MapMode.Animation || mapMode == MapMode.Heatmap) && !timeframe.isValid) ?
            <div>
                <p>Timeframe: <b>{queryTimeframe.name}</b></p>
                <p>Timeframe contains no events</p>
            </div> : null
        }
    </div>;
}

MapboxPlot.propTypes = {
    sensors: PropTypes.array,
    gateways: PropTypes.array,
    selectedSensor: PropTypes.object,
    clickSensor: PropTypes.func,
    alerts: PropTypes.array,
    events: PropTypes.array,
    mapboxLayout: PropTypes.object,
    mapMode: PropTypes.object,
    timeframe: PropTypes.object,
    config: PropTypes.object
};

function buildGeoJson(sensors, alerts, selectedSensor){

    const alertSensorMap = new Map();

    alerts.forEach(alert => {
        let alertObj = alertSensorMap.get(alert.sensorId);
        if(!alertObj){
            alertObj = {alerts: new Set()};
        }
        alertObj.alerts.add(alert.label);
        alertSensorMap.set(alert.sensorId, alertObj);
    });

    const features = sensors.map((sensor) => {
        const sensorAlerts = alertSensorMap.get(sensor.id)?.alerts;
        return {
            type: 'Feature',
            "id": sensor.id,
            properties: {
                "name": `${sensor.deviceId}`,
                "sensorId": sensor.id,
                "alertsPresent": alertSensorMap.get(sensor.id) !== undefined ,
                "alerts": sensorAlerts ? Array.from(sensorAlerts).join(", ") : [],
                "selected": selectedSensor && selectedSensor.id === sensor.id ? 'selected': 'deselected'
            },
            geometry: {
                type: 'Point',
                'coordinates': [
                    sensor.currentLocation.longitude,
                    sensor.currentLocation.latitude
                ]
            }
        };
    });

    const geojson = {
        'type': 'FeatureCollection',
        'features': features
    };
    return geojson;
}

function buildGeoEventsHeatmap(events, heatmapClusters){

    if (heatmapClusters.length > 0){
        events = heatmapClusters.flatMap(cluster => cluster.events);
    }

    const features = events.filter(event => event.sensor.currentLocation !== null).map(event => {
        const sensor = event.sensor;
        return {
            type: 'Feature',
            properties: {
                "name": `Sensor ${sensor.id}`,
                "sensorId": sensor.id
            },
            geometry: {
                type: 'Point',
                'coordinates': [
                    sensor.currentLocation.longitude,
                    sensor.currentLocation.latitude
                ]
            }
        };
    });

    const geojson = {
        'type': 'FeatureCollection',
        'features': features
    };
    return geojson;
}


function filterEvent(event, timestep){
    return timestep - event.timestamp > -600000 && timestep - event.timestamp < 1200000
}

function eventWeight(event, timestep){
    const msAgo = timestep - event.timestamp;
    if(msAgo < 0 && msAgo > -600000){
        return - (msAgo / 600000);
    } else if(msAgo >= 0 && msAgo < 600000){
        return 1;
    } else if (msAgo >= 600000 && msAgo < 1200000) {
        return (msAgo - 600000) / 600000;
    } else {
        return 0;
    }
}

function buildGeoEventsAnimation(events, timestep, position){

    const features = events
        .filter(event => event.sensor.currentLocation !== null)
        .filter(event =>  filterEvent(event, timestep.getTime()))
        .map(event => {
                const sensor = event.sensor;
                const msAgo = timestep.getTime() - event.timestamp;
                return {
                    type: 'Feature',

                    properties: {
                        "name": `Sensor ${sensor.id}`,
                        "sensorId": sensor.id,
                        "weight": eventWeight(event, timestep.getTime())

                    },
                    geometry: {
                        type: 'Point',
                        'coordinates': [
                            sensor.currentLocation.longitude,
                            sensor.currentLocation.latitude
                        ]
                    }
                };
            }
        );

    const geojson = {
        'type': 'FeatureCollection',
        'features': features
    };
    return geojson;
}

const initialTimeframe = {
    startTime: new Date(new Date().getTime() - 1000),
    endTime: new Date()
}

export default MapboxPlot;
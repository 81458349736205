import React, {useContext, useState} from "react";
import {Button, Dialog, DialogBody, DialogFooter, FormGroup, HTMLSelect, NumericInput} from "@blueprintjs/core";
import PropTypes from "prop-types";
import {AppConfigContext} from "../../context/AppConfigContextProvider";
import {SelectedOrganizationContext} from "../../context/SelectedOrganizationContextProvider";

export default function BaseAppParamDialog(
    {
        appParams, device, deviceMutation, isDialogOpen, closeDialog, initialSettings = {}, confirmedSettings = {},
        extraButtons
    }
) {

    const [updatedSettings, setUpdatedSettings] = useState(initialSettings);

    const debug = false;

    const {organization} = useContext(SelectedOrganizationContext);

    const devAdmin = organization.hasRole("admin-dev");
    const availableParams = appParams.filter(param => devAdmin || param.userEditable);

    function save(){
        deviceMutation.mutate({
            deviceId: device.id,
            action: "update",
            appParams: updatedSettings
        });
        closeDialog();
    }

    function setParamValue(param, value){
        setUpdatedSettings({
            ...updatedSettings,
            [param.name]: value
        });
    }

    function cancel(){
        setUpdatedSettings(initialSettings);
        closeDialog();
    }

    function getDefaultValue(param){
        return param.valueAttributes.default_value;
    }

    function getParamValue(param){
        if (updatedSettings[param.name] !== undefined) {
            return updatedSettings[param.name];
        }
        return getDefaultValue(param);
    }

    function getParamConfirmedValue(param){
        return confirmedSettings[param.name] || getDefaultValue(param);
    }

    function getInvalidReason(param){
        const value = getParamValue(param);
        const min = getParamMin(param);
        const max = getParamMax(param);
        if(param.valueType === 'FLOAT' || param.valueType === 'INTEGER'){
            if(value < min){
                return `Value must be greater than or equal to ${min}`;
            }
            if(value > max){
                return `Value must be less than or equal to ${max}`;
            }
        }
        return null;
    }

    function getDescriptiveConfirmedValue(param){
        const value = getParamConfirmedValue(param);
        if (param.valueType === 'ENUM') {
            const enumValue = param.enumValues.find(v => v.value === value);
            return enumValue ? enumValue.name : value;
        }
        return value;
    }
    function getHelperText(param){
        return <span>
            { param.description }
            {param.valueType === 'FLOAT' && <p> <b>Range</b>: {getParamMin(param)} - {getParamMax(param)}</p>}
            {param.valueType === 'INTEGER' && <p> <b>Range</b>: {getParamMin(param)} - {getParamMax(param)}</p>}

            { getParamValue(param) != getParamConfirmedValue(param) &&  <p><b>Delivered: {getDescriptiveConfirmedValue(param)}</b></p>}
            { !isValid(param) &&  <p><b>{getInvalidReason(param)}</b></p>}
        </span>;
    }

    function getLabelInfo(param){
        return <span>
            {param.valueType === 'FLOAT' && <span> (Float) </span>}
            {param.valueType === 'INTEGER' && <span> (Integer) </span>}
            {param.valueType === 'ENUM' && <span> (Enum)</span>}
        </span>;
    }

    function getParamMax(param){
        if(devAdmin && param.valueAttributes.firmware_max !== undefined){
            return param.valueAttributes.firmware_max;
        } else {
            return param.valueAttributes.max_value;
        }
    }

    function getParamMin(param){
        if(devAdmin && param.valueAttributes.firmware_min !== undefined) {
            return param.valueAttributes.firmware_min;
        } else {
            return param.valueAttributes.min_value;
        }
    }

    function getStepSize(param){
        if(param.valueAttributes.step_size !== undefined){
            return param.valueAttributes.step_size;
        }
        if(param.valueType === 'FLOAT'){
            return 0.01;
        }
        return 1;
    }

    function getMinorStepSize(param){
        if(param.valueAttributes.minor_step_size !== undefined){
            return param.valueAttributes.minor_step_size;
        }
        return getStepSize(param);
    }

    function getMajorStepSize(param){
        if(param.valueAttributes.major_step_size !== undefined){
            return param.valueAttributes.major_step_size;
        }
        return getStepSize(param) * 10;
    }

    function isValid(param){
        const value = getParamValue(param);
        const min = getParamMin(param);
        const max = getParamMax(param);
        if(param.valueType === 'FLOAT' || param.valueType === 'INTEGER'){
            return value >= min && value <= max;
        }
        return true;
    }
    function isFormValid(){
        return availableParams.every(isValid);
    }

    return <Dialog title="App Params" icon="info-sign" isOpen={isDialogOpen} onClose={cancel}>
        <DialogBody>
            {
                availableParams.map((param, index) =>
                    <FormGroup
                        key={param.id}
                        helperText={getHelperText(param)}
                        label={param.shortDescription}
                        labelFor={`input-${param.id}`}
                        labelInfo={getLabelInfo(param)}
                        className={isValid(param) ? "" : "form-invalid"}
                    >
                        {   (param.valueType === 'FLOAT' || param.valueType === 'INTEGER') &&
                            <NumericInput
                                id={`input-${param.id}`}
                                placeholder=""
                                value={getParamValue(param)}
                                onValueChange={(value, s) => setParamValue(param, s)}
                                min={getParamMin(param)}
                                max={getParamMax(param)}
                                stepSize={getStepSize(param)}
                                minorStepSize={getMinorStepSize(param)}
                                majorStepSize={getMajorStepSize(param)}
                            />
                        }
                        {param.valueType === 'ENUM' &&
                            <HTMLSelect
                                id={`input-${param.id}`}
                                onChange={evt => setParamValue(param, parseInt(evt.target.value))}
                                value={`${getParamValue(param)}`}
                                fill={true}
                                options={param.enumValues.map(v => ({
                                    value: `${v.value}`,
                                    label: `${v.name}`
                                }))}
                            />
                        }
                    </FormGroup>
                )
            }

            {
                debug && <>
                <pre>{JSON.stringify(updatedSettings, null, 2)}</pre>
                </>
            }


        </DialogBody>
        <DialogFooter actions={<>
            {extraButtons}
            <Button intent="none" text="Cancel" onClick={cancel} />
            <Button intent="primary" text="Save" disabled={!isFormValid()} onClick={() => save()} />
            </>}
        />
    </Dialog>
}

BaseAppParamDialog.propTypes = {
    appParams: PropTypes.array,
    device: PropTypes.object,
    deviceMutation: PropTypes.object,
    isDialogOpen: PropTypes.bool,
    closeDialog: PropTypes.func,
    initialSettings: PropTypes.object,
    confirmedSettings: PropTypes.object,
    extraButtons: PropTypes.element
}
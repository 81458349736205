import React, {useContext, useEffect, useState} from "react";
import {DeviceListContext} from "../context/DeviceListContextProvider";
import {ButtonGroup, EditableText, Elevation, NonIdealState} from "@blueprintjs/core";
import {Button, Card} from "@blueprintjs/core";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import {SelectedOrganizationContext} from "../context/SelectedOrganizationContextProvider";
import {Link, Navigate} from "react-router-dom";
import FullscreenSpinner from "../components/FullscreenSpinner";
import PropTypes from "prop-types";
import Moment from "react-moment";
import {lastSeenTimeColor} from "../utils/deviceHealthColors";
import BatteryIndicator from "../components/BatteryIndicator";
import moment from "moment";
import {LinkContainer} from "react-router-bootstrap";
import {BuildButtons} from "../components/setup/buttons";
import {SensorRow} from "../components/setup/SensorRow";


function SetupZone(params){

    const {organization, zone, getPageUrl} = useContext(SelectedOrganizationContext);
    const {sensors, gateways, sensorQuery, sensorMutation} = useContext(DeviceListContext);

    const queryClient = useQueryClient();

    const commissionedSensors = sensors.filter(s => s.bucket === "COMMISSIONED");
    const uncommissionedSensors = sensors.filter(s => s.bucket === "UNCOMMISSIONED");
    const deactivatedSensors = sensors.filter(s => s.bucket === "DEACTIVATED");

    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    // Re-enable buttons after timeout
    useEffect(() => {
        if (buttonsDisabled){
            const timeout = setTimeout(() => setButtonsDisabled(false), 500);
            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [buttonsDisabled]);

    // Use as an expression that when changed disables UI buttons.
    const buttonDisableTrigger = sensors.map(s => {
        return {id:s.id, c:s.bucket};
    });

    // Disable buttons after a change in sensors or commission state.
    useEffect(() => {
        setButtonsDisabled(true);
    }, [JSON.stringify(buttonDisableTrigger)])

    const completeMutation = useMutation({
        mutationFn: form => {
          return axios.post(`/organization/${organization.id}/zone/${zone.id}/complete`, {})
        },
    });

    if (completeMutation.isSuccess){
        return <Navigate to={getPageUrl("map")} />
    }

    if (sensors.length === 0)
        return <div className="mt-3 d-flex flex-column" style={{height: "100%"}}>
                <NonIdealState
                    title={"No Sensors Found"}
                    description={`Turn on sensors `}
                />
        </div>;

    if (sensorMutation.isLoading)
        return <FullscreenSpinner />;

    return <div>
        { commissionedSensors.length > 0 && <div className="display-4 mt-3 mb-2"> Commissioned Sensors </div> }
        {

            commissionedSensors.map(sensor =>
                <SensorRow
                    key={sensor.id} sensor={sensor} buttons={
                        <BuildButtons  sensor={sensor} zone={zone} sensorMutation={sensorMutation} disabled={buttonsDisabled} />
                    }
                    sensorMutation={sensorMutation}
                />
            )
        }

        <div className="display-4 mt-5 mb-2"> Uncommissioned Sensors </div>
        {
            uncommissionedSensors.map(sensor =>
                <SensorRow
                    key={sensor.id}
                    sensor={sensor}
                    enableBatteryWarning={true}
                    buttons={<BuildButtons sensor={sensor} zone={zone} sensorMutation={sensorMutation} disabled={buttonsDisabled} />}
                    sensorMutation={sensorMutation}
                />
            )
        }
        { uncommissionedSensors.length === 0 &&
            <div className="mt-3 d-flex flex-column align-items-center">
                <NonIdealState
                        icon={""}
                        title={"No Uncommissioned Sensors Found"}
                        description={`All sensors have been commissioned.`}
                />
                <div className="mt-4">
                    <Button size="lg" onClick={() => completeMutation.mutate({})} disabled={buttonsDisabled}> Complete </Button>
                </div>
            </div>
        }

        { deactivatedSensors.length > 0 && <div className="display-4 mt-5 mb-2"> Deactivated Sensors </div> }
        {
            deactivatedSensors.map(sensor =>
                <SensorRow
                    key={sensor.id} sensor={sensor}
                    buttons={<BuildButtons sensor={sensor} zone={zone} sensorMutation={sensorMutation} disabled={buttonsDisabled} />}
                    sensorMutation={sensorMutation}
                />
            )
        }

    </div>;

}

export default SetupZone;

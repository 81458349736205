import React from "react";
import {useQuery} from "@tanstack/react-query";
import {getApiConfig, getAppParams, getGatewayAppParams} from "../api";
import FullscreenSpinner from "../components/FullscreenSpinner";
import PropTypes from "prop-types";

const AppConfigContext = React.createContext({});

function AppConfigContextProvider({children}){

    const configQuery = useQuery({
        queryKey:[`config`],
        queryFn: (arg) => {
            return getApiConfig();
        },
        refetchOnWindowFocus: false
    });

    const appParamQuery = useQuery({
        queryKey:[`appParams`],
        queryFn: (arg) => {
            return getAppParams();
        },
        refetchOnWindowFocus: false
    });

    const appGatewayParamQuery = useQuery({
        queryKey:[`gatewayParams`],
        queryFn: (arg) => {
            return getGatewayAppParams();
        },
        refetchOnWindowFocus: false
    });

    const appParamAvailable = appParamQuery.isSuccess && appParamQuery.data !== null;
    const appGatewayParamAvailable = appGatewayParamQuery.isSuccess && appGatewayParamQuery.data !== null;
    const configDataAvailable = configQuery.isSuccess && configQuery.data !== null;

    if (!configDataAvailable)
        return <FullscreenSpinner />;

    const context = {
        config: configQuery.data,
        appParams: appParamAvailable ? appParamQuery.data.params : [],
        gatewayParams: appGatewayParamAvailable ? appGatewayParamQuery.data.params : []
    };

    return <AppConfigContext.Provider value={context}>
        {children}
    </AppConfigContext.Provider>;
}

AppConfigContextProvider.propTypes = {
    children: PropTypes.element
};

export {AppConfigContextProvider as default, AppConfigContext};
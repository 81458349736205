import React from "react";
import {Reception0, Reception1, Reception2, Reception3, Reception4} from "react-bootstrap-icons";
import PropTypes from "prop-types";

function RssiIndicator({val}){

    const iconMap = {
        0: <Reception0 />,
        1: <Reception1 />,
        2: <Reception2 />,
        3: <Reception3 />,
        4: <Reception4 />
    };
    if (val === undefined || val == null)
        return <span className={`rssi-indicator rssi-indicator-unknown`}>{iconMap[3]}</span>;

    return <span className={`rssi-indicator rssi-indicator-${val}`}>{iconMap[val]}</span>;
}

RssiIndicator.propTypes = {
    val: PropTypes.number
};

export default RssiIndicator;

import React, {useContext, useMemo} from "react";
import PropTypes from "prop-types";
import {OrganizationListContext} from "./OrganizationListContextProvider";
import {useNavigate} from "react-router-dom";

const SelectedOrganizationContext = React.createContext({});

function SelectedOrganizationContextProvider({orgId, zoneId, children}){

    const organizationId = orgId;

    const navigate = useNavigate();
    const {orgList} = useContext(OrganizationListContext);

    const organization = useMemo(
        () => orgList.find(org => org.id === organizationId),
        [orgList, organizationId]
    );

    const zone = useMemo(
        () => {
            if (organization === undefined)
                return undefined;
            return organization.zones.find(zone => zone.id === zoneId)
        },
        [orgList, organization, zoneId]
    );

    function setSelectedOrg(org){
        setSelectedOrgId(org.id);
    }

    function setSelectedOrgId(orgId){
        navigate(`/org/${orgId}/map`);
    }

    function setZoneId(zoneId){
        navigate(`/org/${orgId}/zone/${zoneId}/map`);
    }

    function setSelectedZone(zone){
        setZoneId(zone.id);
    }

    function getPageUrl(page){
        if (zone !== undefined){
            return `/org/${orgId}/zone/${zoneId}/${page}`;
        } else {
            return `/org/${orgId}/${page}`;
        }
    }

    function getOrgPageUrl(page){
        return `/org/${orgId}/${page}`;
    }

    const context = {organization, organizationId, setSelectedOrg, setSelectedOrgId, setSelectedZone, zone, getPageUrl, getOrgPageUrl};

    return <SelectedOrganizationContext.Provider value={context}>
        {children}
    </SelectedOrganizationContext.Provider>;
}

SelectedOrganizationContextProvider.propTypes = {
    children: PropTypes.element,
    orgId: PropTypes.number,
    zoneId: PropTypes.number
};

export {SelectedOrganizationContextProvider as default, SelectedOrganizationContext};
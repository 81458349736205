import React, {useContext, useEffect, useMemo, useState} from "react";
import {Gateway, Sensor} from "../api";
import PropTypes from "prop-types";
import {DeviceListContext} from "./DeviceListContextProvider";
import {SelectedOrganizationContext} from "./SelectedOrganizationContextProvider";

const SelectedDeviceContext = React.createContext({});

function SelectedDeviceContextProvider({children}) {

    const [selectedDeviceId, setSelectedDeviceId] = useState(null);

    const {organizationId} = useContext(SelectedOrganizationContext);
    const {sensors, gateways} = useContext(DeviceListContext);

    // setSelectedDevice  should save the ID and type only. It is then built from the query results.
    function setSelectedDevice(device){
        if(device === null){
            setSelectedDeviceId(null);
        } else {
            let type = null;
            if (device instanceof Gateway){
                type = 'gateway';
            }
            if (device instanceof Sensor){
                type = 'sensor';
            }
            const newSelectedDevice = {
                type: type,
                id: device.id
            };
            setSelectedDeviceId(newSelectedDevice);
        }
    }

    const selectedDevice = useMemo(() => {
        let found = null;
        if (
            selectedDeviceId !== null
            && selectedDeviceId.type === 'gateway'
        ) {
            found = gateways.find(item => item.id === selectedDeviceId.id);
        }

        if (
            selectedDeviceId !== null
            && selectedDeviceId.type === 'sensor'
        ) {
            found = sensors.find(item => item.id === selectedDeviceId.id);
        }
        if (found !== undefined)
            return found;
        else
            return null;
    },
    [selectedDeviceId, sensors, gateways]
    );

    useEffect(
        () => { setSelectedDevice(null); },
        [organizationId]
    );

    const context = {selectedDevice, selectedDeviceId, setSelectedDevice};

    return <SelectedDeviceContext.Provider value={context}>
        {children}
    </SelectedDeviceContext.Provider>;
}


SelectedDeviceContextProvider.propTypes = {
    children: PropTypes.element
};

export {SelectedDeviceContextProvider as default, SelectedDeviceContext};



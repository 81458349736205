import React, {useRef, useEffect, useMemo, useState, useContext} from "react";
import {useParams, Link, useSearchParams} from "react-router-dom";
import PropTypes from "prop-types";
import Sensors from "./Sensors";
import {useQuery} from "@tanstack/react-query";
import {getEvents, getGateways, getLogEvents, getSensors} from "../api";
import {Icon, IconSize, NonIdealState} from "@blueprintjs/core";
import Moment from "react-moment";
import {Alert, Button, Col, Container, Dropdown, Form, Row} from "react-bootstrap";
import {CaretDownFill} from "react-bootstrap-icons";
import DateRangeSelector from "../components/DateRangeSelector";
import { LinkContainer } from 'react-router-bootstrap';
import {DeviceListContext} from "../context/DeviceListContextProvider";
import {TimeframeContext} from "../context/TimeframeContextProvider";
import {SelectedOrganizationContext} from "../context/SelectedOrganizationContextProvider";


function NoData(props){
    return (
        <Container>
            <NonIdealState
                icon={"search"}
                iconSize={"lg"}
                title={"No Logs Found"}
                description={"Logs will appear here when they are reported by the selected gateway."}
                layout={"vertical"}
            />
        </Container>
    );
}

const logLevels = [
    "ERROR",
    "WARN",
    "INFO",
    "DEBUG"
];

function LogView(props){

    const {gatewayId:gatewayParam} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    // console.log({searchParams});
    const sensorParam = searchParams.get('sensorId');

    const sensorId = sensorParam ? +sensorParam : null;
    const gatewayId = gatewayParam ? +gatewayParam : null;

    const {timeframe, setCustomTimeframe: setCustomQueryTimeframe} = useContext(TimeframeContext);
    const {gateways} = useContext(DeviceListContext);
    const {getOrgPageUrl} = useContext(SelectedOrganizationContext);

    const {organization} = useContext(SelectedOrganizationContext);

    const organizationId = organization.id;

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [rowLimit, setRowLimit] = useState(500);

    const [filterLevel, setFilterLevel] = useState("INFO");

    const eventQuery = useQuery({
        queryKey: [`logevents`, organizationId, gatewayId, timeframe.startTime, timeframe.endTime, sensorId],
        queryFn: (arg) => {
            console.log({gatewayParam, gatewayId});
            return getLogEvents(organizationId, rowLimit, sensorId, gatewayId !== 0 ? gatewayId : null, timeframe.startTime, timeframe.endTime);
        },
        refetchOnWindowFocus: true,
        enabled: true
    });

    let sensorLabel = "Devices";

    const currentGateway = gateways.find(gw => gw.id === gatewayId);
    if(currentGateway !== undefined) {
        sensorLabel = `Gateway ${currentGateway.deviceId}`;
    }

    const dataAvailable = eventQuery.isSuccess && eventQuery.data.events !== null;

    const rowLimitExceeded = dataAvailable && eventQuery.data.events.length === rowLimit;

    const filterLevelIndex = logLevels.indexOf(filterLevel);

    function isDisplayed(logLevel){
        return logLevels.indexOf(logLevel) <= filterLevelIndex;
    }

    let filteredEvents = [];
    if (dataAvailable) {
        filteredEvents = eventQuery.data.events.filter(event => isDisplayed(event.level));
    }

    return (
        <div>
            { rowLimitExceeded
                ? <Container>
                    <Alert variant="warning">
                        Warning: Record Limit Exceeded: {rowLimit} records.
                        Retry the search with a shorter time period.
                    </Alert>
                </Container>
                : null
            }
            <Row className="top-selector-row">
                <Col className="d-flex">
                    <Dropdown className="log-filter-bar">
                        <Dropdown.Toggle variant="secondary">{sensorLabel}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                gateways.map(gateway =>
                                    <LinkContainer to={getOrgPageUrl(`log/${gateway.id}`)}
                                        key={gateway.id}
                                    >
                                        <Dropdown.Item>
                                            Gateway - {gateway.deviceId}
                                        </Dropdown.Item>
                                    </LinkContainer>
                                )
                            }
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown className="log-filter-bar">
                        <Dropdown.Toggle variant="secondary">Level: {filterLevel}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                logLevels.map(level =>
                                        <Dropdown.Item key={level} onClick={() => setFilterLevel(level)}>
                                            {level}
                                        </Dropdown.Item>
                                )
                            }
                        </Dropdown.Menu>
                    </Dropdown>

                    <div className="spacer"/>
                    <Button onClick={() => setShowDatePicker(prev => !prev)} variant="light">
                        <Moment format="YYYY/MM/DD">{timeframe.startTime}</Moment> - <Moment
                        format="YYYY/MM/DD">{timeframe.endTime}</Moment>
                        &nbsp;<CaretDownFill size={12}/>
                    </Button>
                </Col>
            </Row>
            {
                showDatePicker ?
                <Row className="date-picker-row">
                    <Col md={{offset: 4, span: 8}}>
                        <DateRangeSelector
                            organization={organization}
                            timeframe={timeframe}
                            setCustomTimeframe={timeframe => {
                                setCustomQueryTimeframe(timeframe);
                                setShowDatePicker(false);
                            }}
                        />
                    </Col>
                </Row>
                : null
            }
            {
                dataAvailable ?
                    <div>
                        {filteredEvents.map(logEvent =>
                            <div key={logEvent.id}>
                                <b>{logEvent.level}</b>: <Moment>{logEvent.timestamp}</Moment>
                                <pre>{logEvent.message}</pre>
                                {/*<div>{logEvent.isMultiLine ? "multiline": "single line"}</div>*/}
                                {/*<pre>{JSON.stringify(logEvent.json, null, 2)}</pre>*/}
                            </div>
                        )}
                    </div>
                :null
            }
            {
                (dataAvailable && filteredEvents.length === 0 ) ?
                    <NoData /> : null
            }
        </div>
    );
}

LogView.propTypes = {}

export default LogView;
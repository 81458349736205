import {Navigate, Outlet, Route, Routes, useInRouterContext, useParams} from "react-router-dom";
import NavbarView from "./components/NavbarView";
import MapNavbarView from "./components/MapNavbarView";
import {Container} from "react-bootstrap";
import Sensors from "./pages/Sensors";
import LogView from "./pages/LogView";
import React, {Suspense, useState, useEffect, useMemo, useContext} from "react";
import FullscreenSpinner from "./components/FullscreenSpinner";
import DeviceListContextProvider from "./context/DeviceListContextProvider";
import OrganizationListContextProvider, {OrganizationListContext} from "./context/OrganizationListContextProvider";
import WeatherContextProvider from "./context/WeatherContextProvider";
import SelectedDeviceContextProvider from "./context/SelectedDeviceContextProvider";
import SelectedOrganizationContextProvider from "./context/SelectedOrganizationContextProvider";
import AddZone from "./pages/AddZone";
import SetupZone from "./pages/SetupZone";
import PropTypes from "prop-types";
import AuthorizationRequired from "./components/AuthorizationRequired";

const MetricsView = React.lazy(() => import("./pages/MetricsView"));


function OrgWrapper(){
    const {orgId} = useParams();

    return <SelectedOrganizationContextProvider orgId={+orgId}>
        <Outlet />
    </SelectedOrganizationContextProvider>;
}

function ZoneWrapper(){
    const {orgId, zoneId} = useParams();

    return <SelectedOrganizationContextProvider orgId={+orgId} zoneId={+zoneId} >
        <Outlet />
    </SelectedOrganizationContextProvider>;
}

function DefaultRedirect(){
    const {orgList} = useContext(OrganizationListContext);
    return <Navigate to={`org/${orgList[0].id}/map`} />;
}

function ViewContextProvider({children}){
    return <AuthorizationRequired>
            <DeviceListContextProvider>
                <SelectedDeviceContextProvider>
                    <WeatherContextProvider>
                        {children}
                    </WeatherContextProvider>
                </SelectedDeviceContextProvider>
            </DeviceListContextProvider>
        </AuthorizationRequired>;
}

ViewContextProvider.propTypes = {
    children: PropTypes.element,
};

function PageRoutes({path, wrapper}){
    return <Route path={path} element={wrapper}>
            <Route path="map" element={
                <>
                    <NavbarView navbarItems={<MapNavbarView/>} />
                    <Container>
                        <ViewContextProvider>
                            <Sensors/>
                        </ViewContextProvider>
                    </Container>
                </>
            }/>
            <Route path="log">
                <Route path=":gatewayId" element={
                    <>
                        <NavbarView navbarItems={null} />
                        <Container>
                            <ViewContextProvider>
                                <LogView />
                            </ViewContextProvider>
                        </Container>
                    </>
                }/>
            </Route>
            <Route path="metrics" element={
                <>
                    <NavbarView navbarItems={null} />
                    <ViewContextProvider>
                        <Suspense fallback={<FullscreenSpinner />}>
                            <MetricsView />
                        </Suspense>
                    </ViewContextProvider>
                </>
            }/>
            <Route path="addZone" element={
                <>
                    <NavbarView navbarItems={null} />
                    <Container>
                        <ViewContextProvider>
                            <AddZone />
                        </ViewContextProvider>
                    </Container>
                </>
            }/>
            <Route path="setup" element={
                <>
                    <NavbarView navbarItems={null} />
                    <Container className={"full-height-container"}>
                        <ViewContextProvider>
                            <SetupZone />
                        </ViewContextProvider>
                    </Container>
                </>
            }/>
        </Route>;
}

PageRoutes.propTypes = {
    wrapper: PropTypes.element,
    path: PropTypes.string
};

function Router(props){

    return <Routes>
        <Route path="/" element={ <DefaultRedirect /> } />
        {PageRoutes({path: "/org/:orgId", wrapper:<OrgWrapper />})}
        {PageRoutes({path: "/org/:orgId/zone/:zoneId", wrapper:<ZoneWrapper />})}
    </Routes>;
}

Router.propTypes = {
}

export default Router;
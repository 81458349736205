import {useState} from "react";
import {Alert, Row, Col} from "react-bootstrap";
import Moment from 'react-moment';
import React from "react";
import PropTypes from "prop-types";


function AlertBoxes(props){
    const {alerts, onSelectSensor} = props;

    const [dismissed, setDismissed] = useState(new Set());

    function dismiss(alert){
        dismissed.add(alert.id);
        setDismissed(dismissed);
    }
    //const alertBoxes = <div></div>;
    const alertBoxes = (<div>
        {alerts.filter(alert => !dismissed.has(alert.id)).map( alert => (

            <Alert
                key={alert.id}
                variant="danger"
                onClose={() => dismiss(alert)}
                dismissible
                onClick={() => onSelectSensor(alert.sensor)}>

                <Row>
                    <Col xs={12} md={8}>
                        Sensor {alert.sensor.deviceId} detected {alert.articleLabel}. { alert.confidence }% confidence of detection.
                    </Col>
                    <Col xs={12} md={4} className="text-end alert-timestamp">
                        <Moment calendar>{ alert.timestamp }</Moment>
                    </Col>
                </Row>
            </Alert>
            )
        )}
    </div>);
    return alertBoxes;
}

AlertBoxes.propTypes = {
    alerts: PropTypes.array,
    onSelectSensor: PropTypes.func
};

export default AlertBoxes;
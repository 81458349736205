import {Button, Col, Row} from "react-bootstrap";
import Moment from "react-moment";
import React from "react";
import PropTypes from "prop-types";

function ClusterRowView (props){
    const { clusters, onSelectCluster, clusterVariant, clusterColClass } = props;
    return (
        <Row className="cluster-button-row">
            {
                clusters.map((cluster, index) =>
                    <Col md={4} className={["cluster-button-col", "d-grid", clusterColClass(cluster)]} key={index}>
                        <div className="d-grid">
                            <Button onClick={() => onSelectCluster(cluster)} variant={clusterVariant(cluster) }>
                                <Moment format="YYYY/MM/DD H:mm">{cluster.minTime}</Moment> - <Moment format="H:mm">{cluster.maxTime}</Moment>
                            </Button>
                        </div>
                    </Col>
                )
            }
        </Row>
    );
}

ClusterRowView.propTypes = {
    clusters: PropTypes.array,
    onSelectCluster: PropTypes.func,
    clusterVariant: PropTypes.func,
    clusterColClass: PropTypes.func
};

export default ClusterRowView;
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Suspense} from "react";
import Router from "./Router";

import {
    QueryClient,
    QueryClientProvider, useQuery,
} from '@tanstack/react-query';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import OrganizationListContextProvider from "./context/OrganizationListContextProvider";
import MapboxContextProvider from "./context/MapboxContextProvider";
import AppConfigContextProvider from "./context/AppConfigContextProvider";
import TimeframeContextProvider from "./context/TimeframeContextProvider";

const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <AppConfigContextProvider>
                <MapboxContextProvider>
                    <TimeframeContextProvider>
                        <OrganizationListContextProvider>
                            <Router />
                        </OrganizationListContextProvider>
                    </TimeframeContextProvider>
                </MapboxContextProvider>
            </AppConfigContextProvider>
        </QueryClientProvider>
    );
}

export default App;

import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {AppConfigContext} from "../../context/AppConfigContextProvider";
import BaseAppParamDialog from "./BaseAppParamDialog";
import {Button} from "@blueprintjs/core";

export default function AppParamDialog(
    {sensor, sensorMutation, isDialogOpen, closeDialog, initialSettings = {}, confirmedSettings = {}}
) {

    const {appParams} = useContext(AppConfigContext);

    return <BaseAppParamDialog
        appParams={appParams}
        device={sensor}
        deviceMutation={sensorMutation}
        isDialogOpen={isDialogOpen}
        closeDialog={closeDialog}
        initialSettings={initialSettings}
        confirmedSettings={confirmedSettings}
        extraButtons={undefined}
    />
}

AppParamDialog.propTypes = {
    sensor: PropTypes.object,
    sensorMutation: PropTypes.object,
    isDialogOpen: PropTypes.bool,
    closeDialog: PropTypes.func,
    initialSettings: PropTypes.object,
    confirmedSettings: PropTypes.object
}
import {
    Battery,
    BatteryFull,
    BatteryHalf
} from "react-bootstrap-icons";
import PropTypes from "prop-types";
import React from "react";

function BatteryIndicator({val}){

    const iconMap = {
        0: <Battery />,
        1: <BatteryHalf />,
        2: <BatteryFull />,
    };
    if (val === undefined || val == null)
        return <span className={`battery-indicator battery-indicator-unknown`}>{iconMap[0]}</span>;

    return <span className={`battery-indicator battery-indicator-${val}`}>{iconMap[val]}</span>;
}

BatteryIndicator.propTypes = {
    val: PropTypes.number
};

export default BatteryIndicator;

import {NavDropdown} from "react-bootstrap";
import {MapMode, modes} from "../MapboxLayouts";
import React, {useContext} from "react";
import PropTypes from "prop-types";
import NavbarView from "./NavbarView";
import {MapboxContext} from "../context/MapboxContextProvider";


function MapNavbarView(props){

    const {
        setMapboxLayout,
        availableLayouts,
        setMapboxMode: setMapMode,
        mapboxMode:mapMode
    } = useContext(MapboxContext);

    return <>
        <NavDropdown title={`Mode: ${mapMode.name}`}>
            {
                modes.map((layout) =>
                    <NavDropdown.Item key={layout.name} onClick={() => setMapMode(layout)}>
                        {layout.name}
                    </NavDropdown.Item>
                )
            }
            <NavDropdown.Divider />
            {
                availableLayouts.map((layout) =>
                    <NavDropdown.Item key={layout.name} onClick={() => setMapboxLayout(layout)}>
                        {layout.name}
                    </NavDropdown.Item>
                )
            }
        </NavDropdown>
    </>;
}

MapNavbarView.propTypes = {
}

export default MapNavbarView;
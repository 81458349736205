import {useQuery} from "@tanstack/react-query";
import {getWeatherGrids} from "../api";
import React, {useContext, useMemo} from "react";
import lodash from "lodash";
import PropTypes from "prop-types";
import {DeviceListContext} from "./DeviceListContextProvider";

const WeatherContext = React.createContext({});

function WeatherContextProvider({children}){

    const {sensors} = useContext(DeviceListContext);

    // Data queries
    const weatherGridQuery = useQuery({
        queryKey: [`weather-grid-metadata`],
        queryFn: (arg) => {
            return getWeatherGrids();
        },
        refetchOnWindowFocus: false
    });

    const weatherGridDataAvailable = weatherGridQuery.isSuccess && weatherGridQuery.data !== undefined;

    const weatherGridMemoTrigger = weatherGridDataAvailable ? weatherGridQuery.data.raw : null;

    const gridIds = useMemo(() => {
        // To simplify the UI, we disable
        // const gatewayGridIds = gateways.map(device => device.nwsGridId);
        const sensorGridIds = sensors.map(device => device.nwsGridId);

        // const gridIds = gatewayGridIds.concat(sensorGridIds);
        const gridIds = sensorGridIds;
        const ids = [...gridIds.filter(id => id !== null)];
        const idFrequencies = lodash.chain(ids).countBy();

        return idFrequencies.toPairs().sortBy(1).reverse().map(0).value().map(it => parseInt(it));
    }, [sensors])

    const gridMap = useMemo(
        () => weatherGridDataAvailable ?  weatherGridQuery.data.gridMap : new Map(),
        [weatherGridMemoTrigger]
    );

    const weatherGrids = useMemo(
        () => gridIds.map(id => gridMap.get(id)).filter(grid => grid !== undefined),
        [JSON.stringify(gridIds), gridMap]
    );

    const allWeatherGrids = weatherGridDataAvailable ? weatherGridQuery.data.grids : [];

    const context = {allWeatherGrids, weatherGrids};

    return <WeatherContext.Provider value={context}>
        {children}
    </WeatherContext.Provider>;
}

WeatherContextProvider.propTypes = {
    children: PropTypes.element
};

export {WeatherContextProvider as default, WeatherContext};
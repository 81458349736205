import React, {useContext, useMemo, useState} from "react";
import {TimeframeContext} from "../context/TimeframeContextProvider";
import {SelectedOrganizationContext} from "../context/SelectedOrganizationContextProvider";
import {DeviceListContext} from "../context/DeviceListContextProvider";
import {MapboxContext} from "../context/MapboxContextProvider";
import {SelectedDeviceContext} from "../context/SelectedDeviceContextProvider";
import {WeatherContext} from "../context/WeatherContextProvider";
import {AppConfigContext} from "../context/AppConfigContextProvider";
import {Form, Button, Row, Col, Collapse} from "react-bootstrap";
import DeviceSelector from "../components/DeviceSelector";
import RssiIndicator from "../components/RssiIndicator";
import Moment from "react-moment";
import MapboxPlot from "../components/MapboxPlot";
import {MapMode, layouts} from "../MapboxLayouts";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import axios from "axios";
import FullscreenSpinner from "../components/FullscreenSpinner";
import {Navigate} from "react-router-dom";
import {lastSeenTimeColor} from "../utils/deviceHealthColors";

function AddZone(props) {

    const {organization} = useContext(SelectedOrganizationContext);
    const {sensorQuery, gatewayQuery, sensors, gateways} = useContext(DeviceListContext);
    const {mapboxLayout, mapboxMode: mapMode} = useContext(MapboxContext);
    const {allWeatherGrids: weatherGrids} = useContext(WeatherContext);
    const {config} = useContext(AppConfigContext);
    const queryClient = useQueryClient();

    const unassignedGateways = useMemo(
        () => gateways.filter(gw => gw.activeZones.length === 0),
        [gateways]
    )

    const [selectedGatewayId, setSelectedGatewayId] = useState(undefined);
    const [label, setLabel] = useState("");
    const [showMap, setShowMap] = useState(false);

    const selectedGateway = gateways.find(gateway => gateway.id === selectedGatewayId);

    const gatewayRssi = selectedGateway?.status?.rssiDownCategory;
    const gatewayRssiValue = selectedGateway?.status?.rssiDown;

    const mutation = useMutation({
        mutationFn: form => {
          return axios.post(`/organization/${organization.id}/create_zone`, {form})
        },
    });

    if (mutation.isSuccess){
        queryClient.invalidateQueries();

        const {zone} = mutation.data.data;
        const page = "setup";
        return <Navigate to={`/org/${organization.id}/zone/${zone.id}/${page}`} />;
    }

    if (mutation.isLoading)
        return <FullscreenSpinner />;

    const formValid = selectedGateway !== undefined && label?.trim() !== "";

    function save(){
        mutation.mutate({gatewayId: selectedGatewayId, name: label});
        console.log({selectedGatewayId, label});
    }

    return <div className="pt-5 text-center">
        <Row>
            <Col md={{offset:2, span:8}}>
            <Form className="add-zone-form" onSubmit={evt => {
                evt.preventDefault();
                save();
            }}>
                <Form.Group>
                    <Form.Label><span className="display-5">Name of Installation</span></Form.Label>
                    <Form.Control size="lg" type="text" placeholder="Skunkworks" value={label} onChange={evt => setLabel(evt.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label><span className="display-5"> Select Gateway </span></Form.Label>
                    <DeviceSelector
                        gateways={unassignedGateways}
                        sensors={[]}
                        onSelectDevice={gateway => {
                            setSelectedGatewayId(gateway.id);
                            setShowMap(false);
                        }}
                        selectedDevice={selectedGateway}
                    />
                    <div className="mt-2">
                        { showMap &&
                             <MapboxPlot
                                gateways={unassignedGateways}
                                sensors={[]} selectedSensor={selectedGateway}
                                alerts={[]} events={[]}
                                clickSensor={gw => {
                                    setSelectedGatewayId(gw.id)
                                    setShowMap(false);
                                }}
                                mapMode={MapMode.Normal}
                                mapboxLayout={layouts[0]}
                             />
                        }
                        {!showMap && <Button variant="link" onClick={() => setShowMap(true)}>Map</Button>}
                    </div>
                </Form.Group>

                <div className={selectedGateway === undefined ? "box-disabled" : 'box-enabled'}>
                    <Form.Group>
                        <Form.Label><span className="display-5"> Activity </span></Form.Label>
                        <div>
                            Last seen <b style={{color: lastSeenTimeColor(selectedGateway)}}>
                            {
                                selectedGateway?.lastSeen !== undefined
                                ? <Moment fromNow>{selectedGateway?.lastSeen}</Moment>
                                : "Not Available"
                            }
                            </b>.
                        </div>
                        <Form.Text className="text-muted">

                        </Form.Text>
                    </Form.Group>
                </div>

                <div className={selectedGateway === undefined ? "box-disabled" : 'box-enabled'}>
                    <Form.Group>
                        <Form.Label><span className="display-5">Signal</span></Form.Label>

                        <div className={`display-1`}>
                            <RssiIndicator val={gatewayRssi} />
                        </div>

                        {gatewayRssiValue && <div>{gatewayRssiValue} dBm</div>}

                        <Form.Text className="text-muted">
                            { gatewayRssi !== undefined ? "Signal strength of the gateway." : "Signal strength unavailable." }
                        </Form.Text>
                    </Form.Group>
                </div>
                <div className="d-flex flex-column"><Button size={"lg"} type={"submit"} disabled={!formValid}>Continue</Button></div>
            </Form>
            </Col>
        </Row>
    </div>;
}

export default AddZone;
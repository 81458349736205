import React, {useState} from "react";
import PropTypes from "prop-types";
import {last30Days} from "../MapboxLayouts";

const TimeframeContext = React.createContext({});

function TimeframeContextProvider({children}) {

    const [timeframe, setTimeframe] = useState(last30Days);

    function setCustomTimeframe(timeframe) {
        setTimeframe({
            name: "Custom",
            startTime: timeframe[0],
            endTime: timeframe[1]
        })
    }

    const context = {timeframe, setTimeframe, setCustomTimeframe};

    return <TimeframeContext.Provider value={context}>
        {children}
    </TimeframeContext.Provider>;
}

TimeframeContextProvider.propTypes = {
    children: PropTypes.element
};

export {TimeframeContextProvider as default, TimeframeContext};
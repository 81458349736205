import React, {useState, useEffect, useMemo, useCallback, useContext} from "react";
import Moment from "react-moment";
import {archiveSensor, getApiConfig, getSensors, getWeatherGrid, getWeatherTypes, Sensor, getSensorParamDetails} from "../api";
import PropTypes from "prop-types";
import {Form, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "react-bootstrap";
import {Button} from "@blueprintjs/core";
import {Link} from "react-router-dom";
import {ArchiveFill, Gear} from "react-bootstrap-icons";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Switch} from "@blueprintjs/core";
import axios from "axios";
import {DeviceListContext} from "../context/DeviceListContextProvider";
import AppParamDialog from "./setup/AppParamDialog";

function SensorStatusView(props){
    const {selectedSensor, organization, onArchive, weatherGrids} = props;

    const [showArchive, setShowArchive] = useState(false);
    const [permanent, setPermanent] = useState(false);

    const [ showAppParamDialog, setShowAppParamDialog ] = useState(false);

    const [restartRequested, setRestartRequested] = useState(false);

    // We save a local copy of the sensor from mutation responses.

    const {sensorMutation} = useContext(DeviceListContext);

    const mutation = useMutation({
        mutationFn: archiveRequest => {
            return archiveSensor(archiveRequest.organizationId, archiveRequest.sensorId, archiveRequest.permanent);
        },
        onSuccess: () => {
            onArchive()
        }
    });

    // Data queries
    const weatherTypesQuery = useQuery({
        queryKey: [`weatherTypes`],
        queryFn: (arg) => {
            return getWeatherTypes();
        }
    });

    // Queries
    const weatherGridQuery = useQuery({
        queryKey: [`currentWeatherGrid`, selectedSensor.nwsGridId],
        queryFn: (arg) => {
            return getWeatherGrid(selectedSensor.nwsGridId, true);
        },
        refetchOnWindowFocus: true,
        enabled: selectedSensor.nwsGridId !== null

    });

    const appParamDetailsQuery = useQuery({
        queryKey: [`appParamDetails`, selectedSensor.id],
        queryFn: (arg) => {
            return getSensorParamDetails(selectedSensor.id, organization.id);
        },
        enabled: selectedSensor.id !== null
    });


    const weatherDataAvailable = (
        weatherGridQuery.status === 'success' && weatherGridQuery.data !== undefined &&
        weatherTypesQuery.status === 'success' && weatherTypesQuery.data !== undefined
    );

    const weatherGrid = useMemo(
        () => weatherGrids.find(grid => grid.id === selectedSensor.nwsGridId),
        [selectedSensor, weatherGrids]
    );

    function submitArchive(){
        mutation.mutate({
            "organizationId":organization.id, "sensorId": selectedSensor.id, permanent
        });
    }

    const initialValues = useMemo(
        () => {
            if(appParamDetailsQuery.data !== undefined) {
                if (appParamDetailsQuery.data.sensor.latestSensorParamVersion !== null) {
                    return appParamDetailsQuery.data.sensor.latestSensorParamVersion.desiredValues;
                }
            }
            return undefined;
        },
        [appParamDetailsQuery.data]
    );
    console.log({initialValues});

    return (
        <div>
            <Modal show={showArchive}>
                <Form onSubmit={event => {event.preventDefault(); submitArchive();}}>
                    <ModalHeader>Archive Sensor</ModalHeader>
                    <ModalBody>
                        <p>Archiving a sensor will remove it from view and reporting until activity has been seen again.</p>
                        <Switch onClick={() => setPermanent(prev => !prev)} defaultChecked={permanent}>Suppress future events</Switch>
                    </ModalBody>

                    <ModalFooter>
                        <Button intent="none" onClick={() => setShowArchive(false)}>Cancel</Button>
                        <Button intent="danger" type="submit">Archive</Button>
                    </ModalFooter>
                </Form>
            </Modal>
            { selectedSensor.name && <p><b>Sensor Name</b>: {selectedSensor.name}</p> }
            <p><b>Sensor ID</b>: {selectedSensor.deviceId}</p>
            <p><b>Last seen</b>: <Moment fromNow>{selectedSensor.lastSeen}</Moment> </p>
            <p><b>Battery</b>: {selectedSensor.status && selectedSensor.status.battery !== null ? `${selectedSensor.status.battery}%` : "unknown"} </p>
            <p><b>System Status</b>: {selectedSensor.status && selectedSensor.status.systemStatus !== null ? selectedSensor.status.systemStatus : "unknown"} </p>
            <p><b>RSSI Up</b>: {selectedSensor.status && selectedSensor.status.rssiUp !== null ? `${selectedSensor.status.rssiUp} dBm` : "unknown"}</p>
            <p><b>RSSI Down</b>: {selectedSensor.status && selectedSensor.status.rssiDown !== null ? `${selectedSensor.status.rssiDown} dBm` : "unknown"}</p>
            {   weatherDataAvailable ?
                <p><b>Temperature</b>: {Math.round(weatherGridQuery.data.getTemperatureFahrenheit(weatherTypesQuery.data), 2)}&deg;F</p>
                : null
            }
            {
                weatherGrid !== undefined ? <p> Weather Region: {weatherGrid.name} </p> : null
            }
            <div>
                {
                    initialValues !== undefined &&
                        <AppParamDialog
                            initialSettings={initialValues}
                            isDialogOpen={showAppParamDialog}
                            closeDialog={() => setShowAppParamDialog(false)}
                            sensor={selectedSensor}
                            sensorMutation={sensorMutation}
                        />
                }
            </div>

            <div className="status-button-bar">
                {
                    organization.hasRole("admin") && !restartRequested
                    ? <Button intent={"danger"} onClick={() => {
                            sensorMutation.mutate({deviceId: selectedSensor.id, action: "update", restart: true});
                            setRestartRequested(true);

                        }}>Restart</Button>
                    : null
                }
                { restartRequested ? <div> Restart Requested </div> : null }
                {
                    organization.hasRole("admin")
                    ? <Button intent="none" onClick={() => setShowAppParamDialog(true)}><Gear /></Button>
                    : null
                }
                {
                    organization.hasRole("admin")
                    ? <Button intent={"danger"} onClick={() => setShowArchive(true)}>
                            <ArchiveFill /> Archive
                    </Button>
                    : null
                }
            </div>
        </div>
    )
}

SensorStatusView.propTypes = {
    selectedSensor: PropTypes.object,
    organization: PropTypes.object,
    onArchive: PropTypes.func,
    weatherGrids: PropTypes.array
}

export default SensorStatusView
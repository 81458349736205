import Clustering from "density-clustering";
import _ from 'lodash';

class Cluster {
    constructor(events) {
        this.events = events;
    }

    get minTime() {
        return new Date(this.minTimestamp);
    }
    get minTimestamp() {
        return _.min(this.events.map(event => event.timestamp));
    }
    get maxTime() {
        return new Date(this.maxTimestamp);
    }
    get maxTimestamp() {
        return _.max(this.events.map(event => event.timestamp)) + 600000;
    }
    isSensorPresent(sensor){
        if (sensor) {
            return this.events.some(event => event.sensor.id === sensor.id);
        } else {
            return false;
        }
    }
}

function clusterEvents(events){
    function isValidEvent(event){
        return event.sensor != null && event.sensor.currentLocation != null;
    }
    const filteredEvents = events.filter(isValidEvent);



    const points = filteredEvents.map(event => {
        const timestampNormalization = 1 / 6000000; // Time scaling relative to the location
        const longitudeNormalization = Math.cos(event.sensor.currentLocation.latitude); // scale to correspond to curvature of earth.
        const latitudeNormalization = 1;

        return [
            event.timestamp * timestampNormalization,
            event.sensor.currentLocation.longitude * longitudeNormalization,
            event.sensor.currentLocation.latitude * latitudeNormalization
        ];
    });

    const dbscan = new Clustering.DBSCAN();
    const clusters = dbscan.run(points, 0.5, 2);
    const clusterObjects = clusters.map(
        points => new Cluster(points.map(point => events[point]))
    );
    return _.sortBy(clusterObjects, obj => obj.minTime);
}

export {clusterEvents};
import {Container, Navbar, NavDropdown, Nav} from "react-bootstrap";
import {useQuery} from "@tanstack/react-query";
import {getOrganizations, Organization, getApiConfig} from "../api";
import React, {useContext, useEffect} from "react";
import PropTypes from "prop-types";
import {Geo, GeoFill, LockFill, PlusLg, UnlockFill} from 'react-bootstrap-icons';
import {LinkContainer} from 'react-router-bootstrap';

import {OrganizationListContext} from "../context/OrganizationListContextProvider";
import {DeviceListContext} from "../context/DeviceListContextProvider";
import {AppConfigContext} from "../context/AppConfigContextProvider";
import {SelectedOrganizationContext} from "../context/SelectedOrganizationContextProvider";



function NavItems({orgs, icon, onSelect}){
    return <>
        {
            orgs.map(org =>
                <NavDropdown.Item key={org.id} onClick={() => onSelect(org)}>
                    {icon} {org.name}
                </NavDropdown.Item>
            )
        }
    </>;
}

NavItems.propTypes = {
    orgs: PropTypes.array,
    icon: PropTypes.element,
    onSelect: PropTypes.func
};

function NavbarView(props) {

    const {
        navbarItems
    } = props;

    const {config} = useContext(AppConfigContext);
    const {
        organization, setSelectedOrg, zone, setSelectedZone, getPageUrl, getOrgPageUrl
    } = useContext(SelectedOrganizationContext);

    const {loggedIn, orgList} = useContext(OrganizationListContext);

    // default values
    let orgTitle;

    if (zone !== undefined){
        orgTitle = <div className="zone-box">
                <div className="zone-label">{zone.name}</div>
                <div className="org-label">{organization.name}</div>
            </div>;
    } else if (organization !== undefined){
        orgTitle = organization.name;
    } else {
        orgTitle = "Unavailable";
    }

    const privateOrgs = orgList.filter(org => org.private);
    const publicOrgs = orgList.filter(org => !org.private);

    function archivedZoneFilter(zone){
        return zone.archiveStatus === 'ACTIVE';
    }

    // zones for menu list. Only show active zones
    const zones = organization !== undefined ? organization.zones.filter(archivedZoneFilter) : [];

    return (
        <Navbar bg="light" expand="lg">
            <Container fluid="lg">
                <Navbar.Brand>Matrix Industries - Proximity</Navbar.Brand>
                <Navbar.Toggle></Navbar.Toggle>
                <Navbar.Collapse className="justify-content-end">
                    { organization !== undefined && organization.hasRole("admin") &&
                        <LinkContainer to={getOrgPageUrl("addZone")}><Nav.Link>
                            <PlusLg /> New Installation</Nav.Link>
                        </LinkContainer>
                    }
                    {navbarItems}
                    <LinkContainer to={getPageUrl("map")}><Nav.Link>Map View</Nav.Link></LinkContainer>
                    <LinkContainer to={getPageUrl("metrics")}><Nav.Link>Chart View</Nav.Link></LinkContainer>
                    <NavDropdown title={orgTitle}>

                        <NavItems orgs={zones} onSelect={setSelectedZone} icon={<GeoFill />}/>
                        { (zones.length > 0) ? <NavDropdown.Divider /> : null }
                        <NavItems orgs={privateOrgs} icon={<LockFill />} onSelect={setSelectedOrg} />
                        { (privateOrgs.length > 0) ? <NavDropdown.Divider /> : null }
                        <NavItems orgs={publicOrgs} onSelect={setSelectedOrg} icon={<UnlockFill />} />

                        <NavDropdown.Divider />
                        {
                            loggedIn
                            ? <NavDropdown.Item key={"logout"} href={config.logoutUrl}>Logout</NavDropdown.Item>
                            : <NavDropdown.Item key={"login"} href={config.authenticationUrl}>Sign In</NavDropdown.Item>
                        }
                    </NavDropdown>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

NavbarView.propTypes = {
    navbarItems: PropTypes.element
};

export default NavbarView;

class MapboxLayout {
    constructor(name, keyRequired, mapboxStyle, markerColor, layers, gatewayMarkerColor) {
        this.name = name;
        this.keyRequired = keyRequired;
        this.markerColor = markerColor;
        this.mapboxStyle = mapboxStyle;
        this.layers = layers;
        this.gatewayMarkerColor = gatewayMarkerColor ? gatewayMarkerColor : 'lightblue';
    }
}

const lightBackground = new MapboxLayout(
    "Light Background",
    true,
    'mapbox://styles/mapbox/light-v10',
    "darkgreen",
    []
);

const darkBackground = new MapboxLayout(
    "Dark Background",
    true,
    'mapbox://styles/mapbox/dark-v10',
    "lightgreen",
    []

);

const mapboxStreets = new MapboxLayout(
    "Mapbox Streets",
    true,
    "mapbox://styles/mapbox/streets-v11",
    "darkgreen",
    []
);

const mapboxOutdoors = new MapboxLayout(
    "Mapbox Outdoors",
    true,
    "mapbox://styles/mapbox/outdoors-v11",
    "darkgreen",
    []
);

const satelliteStreets = new MapboxLayout(
    "Satellite Streets",
    true,
    "mapbox://styles/mapbox/satellite-streets-v11",
    "lightgreen",
    []
);


const layouts = [
    satelliteStreets,
    lightBackground,
    darkBackground,
    mapboxStreets,
    mapboxOutdoors
];

class MapMode {
    // Create new instances of the same class as static attributes
    static Normal = new MapMode("Normal");
    static Heatmap = new MapMode("Heatmap");
    static Animation = new MapMode("Animation");


    constructor(name) {
        this.name = name;
    }
}


function daysAgo(n){
    const date = new Date();
    date.setDate(date.getDate() - n);
    return date;
}

const last24hours = {
    name: "Last 24 hours",
    startTime: daysAgo(1),
    endTime: new Date()
};

const last7Days = {
    name: "Last 7 days",
    startTime: daysAgo(7),
    endTime: new Date()
};

const last30Days = {
    name: "Last 30 days",
    startTime: daysAgo(30),
    endTime: new Date()
};

const last1Year = {
    name: "Last One Year",
    startTime: daysAgo(365),
    endTime: new Date()
};

const timeframes = [
    last24hours,
    last7Days,
    last30Days,
    last1Year,
]

const modes = [
    MapMode.Normal,
    MapMode.Animation,
    MapMode.Heatmap
];

export { layouts, MapboxLayout, modes, MapMode, timeframes, last24hours, last7Days, last30Days};